@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src: local('Pretendard Black'), url('../assets/fonts/Pretendard-Black.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-Black.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src: local('Pretendard ExtraBold'), url('../assets/fonts/Pretendard-ExtraBold.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-ExtraBold.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'), url('../assets/fonts/Pretendard-Bold.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-Bold.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local('Pretendard SemiBold'), url('../assets/fonts/Pretendard-SemiBold.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-SemiBold.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'), url('../assets/fonts/Pretendard-Medium.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-Medium.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'), url('../assets/fonts/Pretendard-Regular.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-Regular.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'), url('../assets/fonts/Pretendard-Light.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-Light.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src: local('Pretendard ExtraLight'), url('../assets/fonts/Pretendard-ExtraLight.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-ExtraLight.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-display: swap;
  src: local('Pretendard Thin'), url('../assets/fonts/Pretendard-Thin.subset.woff2') format('woff2'), url('../assets/fonts/Pretendard-Thin.subset.woff') format('woff');
}

body { 
  font-family: "Pretendard", sans-serif;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  font-size: 14px; font-weight: 300; letter-spacing: -.3px;
  color: #000;
  position: relative;
  overflow-x: hidden;
  // user-select: none;
}
img, a {
  // user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
}
ul {list-style: none; padding: 0; margin: 0;}
li {list-style: none;}
a {color: #000000; text-decoration: none;cursor: pointer;}
a:hover {color: #333;}
// common
.c-app {color: black;}
.c-wrapper {position: relative;}
.t-main {min-height: 77vh;}
.box-shadow {box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1) !important;}
.box-shadow1 {box-shadow: 1px 2px 16px 0 rgba(255, 113, 113, 0.16) !important;}
.white-bg { background-color: #FFF !important; }
.bg-1 { background-color: #F1F1F1 !important; }
.bg-E5 { background-color: #E5E6E1 !important; }
.bg-D9 { background-color: #D9D9D9 !important; }
.bg-2 { background-color: #B5B5B5 !important; }
.bg-3 { background-color: #353745 !important; }
.bg-9 { background-color: #3B3F4A !important; }
.bg-12 { background-color: #121214 !important; }
.bg-dark {background-color: #000 !important;}
.bg-black {background-color: #070B11 !important;}
.bg-danger {background-color: $danger !important;}
.bg-light-yellow {background-color: #FFF9F0 !important;}
.bg-primary2 {background-color: rgba(0, 117, 155, 0.8) !important;}
.bg-primary3 {background-color: rgba(0, 199, 60, 0.30) !important;}
.bg-trans { background-color: transparent !important; }
.z-top {z-index: 999999; position: relative;}
.minh-5 {min-height: 5rem !important;}
.minh-10 {min-height: 10rem !important;}
.minh-15 {min-height: 15rem !important;}
.minh-20 {min-height: 20rem !important;}
.minh-38 {min-height: 38rem !important;}
.maxh-10 {max-height: 10rem !important;}
.maxh-15 {max-height: 15rem !important;}
.maxh-20 {max-height: 20rem !important;}
.h-22px {height: 22px !important;}
.h-30px {height: 30px !important;}
.h-44px {height: 44px !important;}
.h-46px {height: 46px !important;}
.h-48px {height: 48px !important;}
.h-50px {height: 50px !important;}
.h-52px {height: 52px !important;}
.h-60px {height: 60px !important;}
.h-100vh {height: 100vh !important;}
.max100 { max-width: 100%; }
.max90 { max-width: 90%; }
.max80 { max-width: 80%; }
.maxw-10 { max-width: 10rem; }
.maxw-54 { max-width: 54px; }
.width50 { width: 50% !important; display: inline-block; }
.w-53 { width: 53% !important; }
.w-30 { width: 30% !important; display: inline-block; }
.w-20 { width: 20% !important; display: inline-block; }
.width24 { width: 24rem !important; }
.width23 { width: 23rem !important; }
.width21 { width: 21rem !important; }
.width20 { width: 20rem !important; }
.width14 { width: 14rem !important; }
.width13 { width: 13rem !important; }
.width12 { width: 12rem !important; }
.width11 { width: 11rem !important; }
.width10 { width: 10rem !important; }
.width9 { width: 9rem !important; }
.width8 { width: 8rem !important; }
.width7 { width: 7rem !important; }
.width6 { width: 6rem !important; }
.width5 { width: 5rem !important; }
.width4 { width: 4rem !important; }
.width3 { width: 3rem !important; }
.width2 { width: 2rem !important; }
.width1 { width: 1rem !important; }
.h-auto {height: auto;}
.w-4px { width: 4px !important; }
.w-5px { width: 5px !important; }
.w-6px { width: 6px !important; }
.w-8px { width: 8px !important; }
.w-9px { width: 9px !important; }
.w-10px{width:10px!important}.w-11px{width:11px!important}.w-12px{width:12px!important}.w-13px{width:13px!important}.w-14px{width:14px!important}.w-15px{width:15px!important}.w-16px{width:16px!important}.w-17px{width:17px!important}.w-18px{width:18px!important}.w-19px{width:19px!important}.w-20px{width:20px!important}.w-21px{width:21px!important}.w-22px{width:22px!important}.w-23px{width:23px!important}.w-24px{width:24px!important}.w-27px{width:27px!important}.w-30px{width:30px!important}.w-32px{width:32px!important}
.w-25px{width:25px!important}.w-28px{width:28px!important}.w-36px{width:36px!important}.w-40px{width:40px!important}.w-48px{width:48px!important}.w-55px{width:55px!important}.w-64px{width:64px!important}
.h-12px { height: 12px !important; }
.h-15px { height: 15px !important; }
.mt-8px { margin-top: 8px !important; }
@for $i from 10 through 20 {
  .mt-#{$i}px { margin-top: #{$i}px !important; }
  .mb-#{$i}px { margin-bottom: #{$i}px !important; }
  .ml-#{$i}px { margin-left: #{$i}px !important; }
  .mr-#{$i}px { margin-right: #{$i}px !important; }
  .mx-#{$i}px { margin-left: #{$i}px !important; margin-right: #{$i}px !important; }
  .my-#{$i}px { margin-top: #{$i}px !important; margin-bottom: #{$i}px !important; }
  .pt-#{$i}px { padding-top: #{$i}px !important; }
  .pb-#{$i}px { padding-bottom: #{$i}px !important; }
  .pr-#{$i}px { padding-right: #{$i}px !important; }
  .px-#{$i}px { padding-left: #{$i}px !important; padding-right: #{$i}px !important; }
  .py-#{$i}px { padding-top: #{$i}px !important; padding-bottom: #{$i}px !important; }
}
@for $i from 4 through 20 {
  .mt-#{$i * 5}px { margin-top: #{$i * 5}px !important; }
  .mb-#{$i * 5}px { margin-bottom: #{$i * 5}px !important; }
  .ml-#{$i * 5}px { margin-left: #{$i * 5}px !important; }
  .mr-#{$i * 5}px { margin-right: #{$i * 5}px !important; }
  .mx-#{$i * 5}px { margin-left: #{$i * 5}px !important; margin-right: #{$i * 5}px !important; }
  .my-#{$i * 5}px { margin-top: #{$i * 5}px !important; margin-bottom: #{$i * 5}px !important; }
  .pt-#{$i * 5}px { padding-top: #{$i * 5}px !important; }
  .pb-#{$i * 5}px { padding-bottom: #{$i * 5}px !important; }
  .pr-#{$i * 5}px { padding-right: #{$i * 5}px !important; }
  .px-#{$i * 5}px { padding-left: #{$i * 5}px !important; padding-right: #{$i * 5}px !important; }
  .py-#{$i * 5}px { padding-top: #{$i * 5}px !important; padding-bottom: #{$i * 5}px !important; }
}
.mt-13 { margin-top: 13px;}
.mt-16 { margin-top: 16px;}
.mt-20 { margin-top: 20px;}
.mt-32 { margin-top: 32px;}
.mt-30 { margin-top: 30px;}
.mt-60 { margin-top: 60px;}
.pt-80 { padding-top: 80px !important;}
.pb-80 { padding-bottom: 80px !important;}
.px-20 { padding-left: 20px; padding-right: 20px;}
.py-30 { padding-top: 30px; padding-bottom: 30px;}
.d-Iblock { display: inline-block !important; }
.align-center { align-items: center !important; display: flex;justify-content: center; }
.align-items-center { align-items: center !important; display: flex;}
.justify-content-center {display: flex; justify-content: center; }
.justify-content-between {display: flex; align-items: center; justify-content: space-between;}
.flex-direction {flex-direction: column;}
.flex-1 {flex: 1;}
.flex-2 {flex: 2;}
.flex-3 {flex: 3;}
.strong, strong, b { font-weight: 700 !important; }
.fw-100 { font-weight: 100 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700, .fw-bold { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }
.fw-900 { font-weight: 900 !important; }
.trans {transition: all 0.3s ease-out;}
.b-none { border: none !important; }
.bt-none { border-top: none !important; }
.bb-none { border-bottom: none !important; }
.bl-none { border-left: none !important; }
.br-none { border-right: none !important; }
.border-1 {border: 1px solid #EEE}
.border-11 {border: 1px solid #757575}
.border-2 { border-bottom: 2px solid #5A5E6A; }
.border-L {border-left: 0.5px solid #000}
.border-R {border-right: 0.5px solid #000}
.border-T {border-top: 0.5px solid #000}
.border-B {border-bottom: 0.5px solid #000}
.border-radius-3 {border-radius: 3px !important;}
.border-radius-10 {border-radius: 10px !important;}
.border-radius-8 {border-radius: 8px !important;}
.border-radius-12 {border-radius: 12px !important;}
.border-radius-100 {border-radius: 100% !important;}
.box-none { box-shadow: none !important; }
.close {font-family: cursive; float: right; cursor: pointer;}
.close2 {font-family: cursive; cursor: pointer;}
.rotate-45 {transform: rotate(45deg);}
.rotate-90 {transform: rotate(-90deg);}
.rotate-180 {transform: rotate(-180deg);}
.btn {
  --cui-btn-disabled-opacity: 0.23;
  font-weight: 500; font-size: 18px; height: 48px;
  transition: .3s ease;
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, //.btn:active:focus, .btn.active:focus, .btn.show:focus,
.btn-check:checked + .btn, .btn-check:active + .btn //, .btn:active, .btn.active, .btn.show 
{
  border-color: transparent; background-color: #121214;
}
.btn-sm {margin: 0; font-size: 0.8rem !important; height: 30px;}
.btn-group.btn-sm > .btn {font-size: 0.8rem; padding: 4px; min-width: 50px;}
.btn-group.btn-sm .btn {height: 30px;}
.btn-default { background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3); }
.btn-default:hover {color: #94969a;background-color: #F4F4EE;}
.btn-default:not([disabled]):not(.disabled):active, .btn-default:not([disabled]):not(.disabled).active, .show > .btn-default.dropdown-toggle {background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3);}
.btn-gray { background-color: gray !important; }
.btn-gray1 { background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3); }
.btn-black, .btn-black:hover, .btn-black:active { background-color: #000 !important; color: white !important; border: 1px solid black !important;}
.btn-dark {background-color: #1F212C !important; color: #FFF; border: none;}
.btn-dark:hover {background-color: #9A9A9A !important;}
.btn-danger, .btn-danger:hover, .btn-danger:active { background-color: $danger !important; color: white; }
.btn-pink, .btn-pink:hover { background-color: $pink !important; color: white; }
.btn-white { background-color: #FFF !important; color: #666 }
.btn-cancel { background-color: rgba(165, 169, 189, 1) !important; color: #FFF }
.btn-success, .btn-success:hover { background-color: $green !important; color: white; }
.btn-warning { background-color: $yellow !important; color: black; border: none;}
.btn-secondary { background-color: #CCC !important; border: none !important;}
.btn-rounded { border-radius: 10rem; }
.btn-outline-danger { color: black; }
.btn-check:checked + .btn-outline-danger {color: white;}
.btn-outline-light { border-color: #CCC !important; }
.btn-outline-dark {border: 1px solid #333 !important; color: #333;}ƒ
.btn-outline-black {border: 1px solid black !important;}
.btn-outline-gray {border-color: #CCC !important;}
.btn-outline-pink, .btn-outline-pink:hover  {border: 1px solid $pink !important; color: $pink;}
.btn-outline-warning {border-color: $yellow !important; color: $yellow !important; background-color: transparent !important;}
.btn-outline-info {border: 1px solid #7ADBFB !important; color: white !important;}
// .btn[class*="btn-outline-"] { border: 1px solid !important }
.btn-danger.disabled, .btn-danger:disabled { background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3); font-weight: bold; }
.btn:disabled, .btn.disabled, fieldset:disabled .btn { border-color: transparent; }
.btn-warning:disabled, .btn-warning.disabled { color: #7F828C; background-color: #3B3F4A !important; }
// button:not(.btn-pill) { font-size: 0.8rem !important; font-weight: 700 !important; border-radius: 3px;}
.btn-pill { border-radius: 50em !important; }
.bg-danger {background-color: $pink;}
.btn-disable { background-color: #353745; border-radius: 10rem; font-size: 16px; color: #5D6078; font-weight: 700;align-items: center !important; display: flex; justify-content: center; height: 50px;}
.disabled, :disabled {cursor: not-allowed;}
select option:disabled { color: #EEE; }
.c-pointer, .pointer {cursor: pointer !important;}
.c-auto {cursor: auto !important;}
.italic {font-style: italic !important;}
.hr-2 { border-top: 2px solid; }
hr {color: #CCC; opacity: 1;}
.wspace-prewrap {white-space: pre-wrap;} 
.word-wrap-break {word-wrap: break-word;}
.word-break-all {word-break: break-all;}
.pre-wrap {white-space: pre-wrap;}
.text-nowrap {white-space: nowrap;}
.text { color: #45484d !important; }
.indent7 { text-indent: -7px; padding-left: 7px;}
.gray-text { color: #9A9A9A !important; }
.gray2-text { color: #5D6078 !important; }
.gray6-text { color: #666 !important; }
.grayC-text { color: #CCC !important; }
.grayE-text { color: #E5E6E1 !important; }
.light-text {color: #A5A9BD !important;}
.white-text { color: #F6F7FA !important; }
.mute-text { color: #D5D8DC !important; }
.dark-text { color: #454f64 !important; }
.dark-text1 { color: #62676a !important; }
.black-text { color: #000 !important; }
.pink-text { color: $pink !important; }
.text-light-blue { color: #4E65A0; }
.azure-light { color: #7ADBFB; }
.success-text { color: $success !important; }
.green-text { color: $green !important; }
.danger-text { color: $danger !important; }
.yellow-text { color: $yellow !important; }
.yellow2-text { color: #EEC84E !important; }
.blue-text { color: rgb(51, 153, 255); }
.under-line { border-bottom: 1px solid !important;}
.underline { text-decoration: underline;}
.line-through { text-decoration: line-through;}
.v-bottom { vertical-align: bottom !important; }
.v-top { vertical-align: top !important; }
.v-ttop { vertical-align: text-top; }
.v-tbottom { vertical-align: text-bottom; }
.v-init { vertical-align: initial; }
.v-middle { vertical-align: middle !important; }
.v-hidden { visibility: hidden !important; }
.card { margin: 0; border-radius: 0; background: #F5F5F5; }
.view img { border-radius: 1px; }
.b-top { border-top: 1px solid #EEE !important;}
.b-bottom { border-bottom: 1px solid #353745 !important;}
.b-radius-0 { border-radius: 0; }
.b-radius1 { border-radius: 1px !important; }
.b-radius-1 { border-radius: .5rem; }
.b-radius-4 { border-radius: 4px; }
.b-radius-8 { border-radius: 8px; }
.b-radius-10 { border-radius: 10px; }
.p-12 {padding: 12px;}
.position-bottom {position: absolute; bottom: 0; left: 0;}
.overflow-x {overflow-x: auto;}
.overflow-y {overflow-y: auto;}
.opacity9 {opacity: .9;}
.opacity8 {opacity: .8;}
.opacity7 {opacity: .7;}
.opacity1 {opacity: .1;}
.f-none { float: none !important; }
.fs5 { font-size: 0.5rem !important; }
.fs6 { font-size: 0.688rem !important; }
.fs7 { font-size: 0.75rem !important; }
.fs8 { font-size: 0.813rem !important; }
.fs9 { font-size: 0.938rem !important; }
.fs10 { font-size: 1.063rem !important; }
.fs11 { font-size: 1.125rem !important; }
.fs12 { font-size: 1.25rem !important; }
.fs13 { font-size: 1.375rem !important; }
.fs14 { font-size: 1.4rem !important; }
.fs15 { font-size: 1.5rem !important; }
.fs16 { font-size: 1.6rem !important; }
.fs17 { font-size: 1.75rem !important; }
.fs2 { font-size: 2rem !important; }
.fs21 { font-size: 2.125rem !important; }
.fs3 { font-size: 3rem !important; }
.fs-10{font-size:10px!important}.fs-11{font-size:11px!important}.fs-12{font-size:12px!important}.fs-13{font-size:13px!important}.fs-14{font-size:14px!important}.fs-15{font-size:15px!important}.fs-16{font-size:16px!important}.fs-17{font-size:17px!important}.fs-18{font-size:18px!important}.fs-19{font-size:19px!important}.fs-20{font-size:20px!important}
.fs-22{font-size:22px!important}.fs-24{font-size:24px!important}.fs-25{font-size:25px!important}.fs-28{font-size:28px!important}.fs-30{font-size:30px!important}.fs-32{font-size:32px!important}.fs-36{font-size:36px!important}.fs-40{font-size:40px!important}.fs-48{font-size:48px!important}.fs-56{font-size:56px!important}.fs-64{font-size:64px!important}
.lh10 {line-height: 1rem;}
.lh13 {line-height: 1.3rem;}
.lh15 {line-height: 1.5rem;}
.lh20 {line-height: 2rem;}
.lh-18px {line-height: 18px;}
.lh-26px {line-height: 26px;}
.lh-35 {line-height: 35px;}
.lh-46 {line-height: 46px;}
.lh0813 {line-height: 0.813rem;}
.ls32 {letter-spacing: .32px;}
.ls43 {letter-spacing: .43px;}
.ls65 {letter-spacing: .65px;}
.ls-41 {letter-spacing: -.41px;}
.ls-71 {letter-spacing: -.71px;}
.label, .inputTitle {font-size: 0.813rem; font-weight: 400;margin-bottom: 0.188rem !important;}
.form-label {font-weight: 400; span {color: #FF0000}}
.col-form-label {font-size: 14px; color: #BEC1C7; padding-top: 11px;}
.br-7 {border-radius: 6.9px !important;}
.br-0 {border-radius: 0 !important;}
.m--1 {margin-left: -15px; margin-right: -15px;}
.mb-4px {margin-bottom: 4px;}
.mb-6px {margin-bottom: 6px;}
.mb-8px {margin-bottom: 8px;}
.mb-12px {margin-bottom: 12px !important;}
.mb-2rem {margin-bottom: 2rem;}
.mb-30 {margin-bottom: 30px !important;}
.mb-40 {margin-bottom: 40px;}
.mb-50 {margin-bottom: 50px;}
.mb-60 {margin-bottom: 60px;}
.mb-68 {margin-bottom: 68px;}
.ml-14 {margin-left: 14px;}
.pr-2rem {padding-right: 2rem;}
.mt--1 {position: relative; top: -1px;}
.mt--2 {position: relative; top: -2px;}
.mt--3 {position: relative; top: -3px;}
.mt--4 {position: relative; top: -4px;}
.mt--5 {position: relative; top: -5px;}
.mt--6 {position: relative; top: -6px;}
.mt--10 {position: relative; top: -10px;}
.error { color: red; font-style: normal; }
.ellipsis1 {display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis2 {display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis4 {display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis5 {display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis-all {-webkit-line-clamp: 999;}
.dropdown-menu.show {border-radius: 0;}
.outline .dropdown-toggle::after {display: none;}
.dropdown-item {font-size: 14px;}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus { background-color: transparent; }
.f-left {float: left;}
.f-right {float: right;}
.clear {clear: both;}
.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
input.security { -webkit-text-security: disc; }
.custom-select:focus,select:focus,.uneditable-input:focus,input[type=color]:focus,input[type=date]:focus,input[type=datetime-local]:focus,input[type=datetime]:focus,input[type=email]:focus,input[type=month]:focus,input[type=number]:focus,input[type=password]:focus,input[type=search]:focus,input[type=tel]:focus,input[type=text]:focus,input[type=time]:focus,input[type=url]:focus,input[type=week]:focus,textarea:focus
{outline:0;box-shadow:none !important; }
.form-control, .form-select, .rs-input { height: 48px; font-size: 18px !important; border-radius: 4px; border-color: #000 !important; color: #000 !important; background: white !important; padding-right: 13px !important;}
.form-control:hover, .form-control:focus {box-shadow: none; color: inherit; border-width: 2px;}
.form-control:disabled, .form-control[readonly] { background-color: #f3f3f3; color: #5A5E6A;}
.form-select {background-size: 13px 8px; background-image: url(../assets/img/i_down.png)}
.was-validated .form-control:invalid, .form-control.is-invalid { border-color: $danger !important; }
.rs-input { height: 3rem !important;}
.md-form.md-outline label { padding-left: 20px; top: 4px; font-size: 0.9rem;}
.md-form.md-outline {margin-bottom: 1rem;}
// .custom-select {padding-left: 5px; background: #FFF url("/img/arrow-down.svg") no-repeat right .5rem center;}
// .custom-select.custom {padding-left: 0; background: #FFF url("/img/arrow-right.svg") no-repeat right .5rem center;}
.form-select-sm { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; height: 30px !important; padding-right: 27px !important;}
.popover-body {font-size: 0.688rem;white-space: pre-wrap;
  em {font-style: normal; color: $danger;}
}
// .collapse, .collapsing {transition: height 0s ease !important; height: auto !important; animation: none;animation-duration: 0s !important;}
// .collapsing {display: none;}
// .collapse.collapsing {display: block;}
.collapse .descript, .collapsing .descript  { padding: 1rem; font-size: 0.938rem; }
.collapse p a {text-decoration: underline;}
.modal-backdrop {background-color: rgba(0,0,0,0.5);}
.modal.fade.show {z-index: 900000;}
.modal-header { border: none; }
.modal-dialog .modal-content {border-radius: 0; }
.modal-body {padding: 20px;
  table th, table td { padding: 3px !important; font-size: 12px; }
}
.modal-footer {border: none; justify-content: center; padding-bottom: 2rem;
  button, button:hover {width: 125px; }
  button.one {flex: none; width: 10rem;}
  .btn-secondary {background: #CCCCCC; border-radius: 6px; color: white;}
  .btn-primary {background: $pink; border-radius: 6px; color: white;}
}
// 
.tippy-box[data-theme~='cpopover'] {background-color: #FEE;border-radius: 0.7rem; border-color: #FEE; font-size: .9rem;}
.tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow::after,
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::after { border-bottom-color: #fee; }
.tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow::before,
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::before { border-bottom-color: #FEE; }
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::after { border-top-color: #fee;}
.custom-control-label {cursor: pointer; font-size: 0.938rem;}
.custom-control-input:checked~.custom-control-label::before{color:#916a6c;border-color:#916a6c;background-color:#916a6c;border-radius:50%;width:20px;height:20px;top:3px}
.custom-radio .custom-control-input:checked~.custom-control-label::after{top:7px;left:-20px;width:10px;height:10px;border-radius:50%;background-color:$danger;background-image:none}
.custom-radio .custom-control-label::before {background-color: #916A6C; border-color: #8a7677; ;}
// checkbox
.custom-checkbox .custom-control-label::before {top: 4px; border-radius: 50% !important; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {top: 4px; left: -24px;}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before 
{border-radius: 5px;  color: $danger; border-color: $danger; background-color: $danger; width: 16px; height: 16px; top: 4px;}
.form-check-input:checked[type=checkbox] {background-image: url('../assets/img/i_checked.png');}
.form-check-input[type=checkbox] {background-image: url('../assets/img/i_check.png');background-color: transparent; border-color: transparent; height: 17px; width: 17px; margin-top: 5px;}
table {
  .form-check-input[type=checkbox] {width: 15px; height: 15px;}
}
.form-check-input:focus { box-shadow: none; }
.form-check-input:checked[type=radio], .radio .form-check-input:checked[type=checkbox] {background-image: url('../assets/img/i_radio_on.png');}
.form-check-input[type=radio], .radio .form-check-input[type=checkbox] {background-image: url('../assets/img/i_radio_off.png');background-color: transparent; border-color: transparent;height: 23px; width: 23px; margin-top: 3px; border-radius: 0;}
.red-label .form-check-input:checked[type=radio] + label {color: $danger;}
.form-check-label {cursor: pointer; font-size: 18px; margin-left: 5px; color: black;}
.tippy-content { max-width: 100%;}
.tippy-box {box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);}
[data-tippy-root] { min-width: 18rem; }
.product-detail [data-tippy-root] { min-width: 20rem; }
.popover-header { border: none; font-weight: bold; padding-bottom: 0; background-color: transparent; padding-top: 1rem;}
.c-switch { width: 3.188rem; height: 2.138rem;}
.c-switch[class*="-3d"] .c-switch-slider::before { top: 2px; left: 5px; width: 1.738rem; height: 1.738rem; }
.nav-tabs { border-bottom: none; flex-wrap: unset;
  .nav-link{border:none;color:#000;font-size:18px;font-weight:700;height:55px;display:flex;align-items:center;justify-content:center;margin:0; cursor: pointer;padding: 0 17px; transition: all 0.3s ease-out; position: relative;}
  .nav-link.active { background-color: transparent; border-bottom: solid 4px #000 !important; }
  .nav-link:after {content:"";width:100%;height:2px; display: inline-block;background:#aaa; position: absolute; bottom: -3px; left: 0;}
  .nav-link.active:after {display: none;}
} 
.pagination {justify-content: center}
.page-link{padding:0;width:42px;height:32px;display:flex;align-items:center;justify-content:center;cursor:pointer;color:#333;border:none !important; border-radius:0!important;margin:0 !important;background-color:transparent !important;font-size:20px; }
.page-link { border-bottom: 2px solid transparent;}
.page-link.active, .active > .page-link { color: black !important; font-weight: 700; }
.page-link.active span, .active > .page-link span { border-bottom: 2px solid;}
.page-link:focus, .page-link:hover {color: inherit;}
.avatar-xxl { width: 6rem; height: 6rem; }
// 
.animated1 {
  animation-duration: 100ms;
  animation-fill-mode: both;
}
.animated2 {
  animation-duration: 200ms;
  animation-fill-mode: both;
}
.animated3 {
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.fade.show {transition: opacity 0.15s linear; opacity: 1 !important;}
.invalid-feedback {font-size: 10px; margin-top: .25rem; color: $danger;}
// slider
.carousel .carousel-indicators li { width: 8px; height: 8px; border-radius: 100%; margin: 0 .3rem; background-color: #CCC; opacity: 1; background-clip: unset; border: none; }
.carousel-indicators .active {background-color: $pink !important;}
.carousel-control-prev, .carousel-control-next { width: 45px; height: 45px; border-radius: 100%; opacity: 1; top: 39%;}
.carousel-control-prev { left: -65px; }
.carousel-control-next { right: -65px; }
.carousel .carousel-control-prev-icon { background-image: url('../assets/img/slider_prev.png'); }
.carousel .carousel-control-next-icon { background-image: url('../assets/img/slider_next.png'); }
.carousel .carousel-control-prev-icon, .carousel .carousel-control-next-icon { width: 13px; height: 19px; }
//
.swiper-button-next:after, .swiper-button-prev:after {display: none;}
.swiper-button-prev, .swiper-button-next {position: absolute; background-position: 50%; background-repeat: no-repeat; background-size: 100% 100%; width: 31px !important; height: 45px !important; top: calc(50% - 0px) !important; }
.swiper-button-prev { background-image: url('../assets/img/slider_prev.png'); left: 0 !important;}
.swiper-button-next { background-image: url('../assets/img/slider_next.png'); right: 0 !important;}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled { opacity: 0 !important; cursor: auto; pointer-events: none; }
.swiper-pagination span { width: 6px; height: 6px; border-radius: 100%; margin: 0 .3rem; background-color: white; opacity: .5; border: none; }
.swiper-pagination .swiper-pagination-bullet-active { background-color: #00759B !important; opacity: 1; width: 23px; border-radius: 10rem;}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction 
{ position: unset; margin-top: 10px;}
// 
.rs-popover {max-width: 20rem;
  span {color: $danger;}
}
// global
::-webkit-input-placeholder {color: #A5A9BD !important;font-size: 18px; font-weight: 300;} /* Edge */
:-ms-input-placeholder {color: #A5A9BD !important;font-size: 18px; font-weight: 300;} /* Internet Explorer 10-11 */
::placeholder {color: #A5A9BD !important;font-size: 18px; font-weight: 300; letter-spacing: -0.75px;}
// input [number] spin disable
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* =============
  Tables
============= */
table {
  border: none;  width: 100%; color: black !important;
  th, td {padding: 7px !important; vertical-align: middle; font-size: 13px;}
  th {background-color: var(--cui-table-hover-bg) !important; font-weight: 500;}
  td {background-color: white !important;}
}
.table-border {border: 1px solid #EEE}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}
@media (min-width: 768px) {
  .fs-md-10{font-size:10px!important}.fs-md-11{font-size:11px!important}.fs-md-12{font-size:12px!important}.fs-md-13{font-size:13px!important}.fs-md-14{font-size:14px!important}.fs-md-15{font-size:15px!important}.fs-md-16{font-size:16px!important}.fs-md-18{font-size:18px!important}.fs-md-20{font-size:20px!important}.fs-md-22{font-size:22px!important}
  .fs-md-24{font-size:24px!important}.fs-md-28{font-size:28px!important}.fs-md-30{font-size:30px!important}.fs-md-32{font-size:32px!important}.fs-md-36{font-size:36px!important}
  .fs-md-40{font-size:40px!important}.fs-md-48{font-size:48px!important}.fs-md-56{font-size:56px!important}.fs-md-64{font-size:64px!important}
  .w-md-10px{width:10px!important}.w-md-11px{width:11px!important}.w-md-12px{width:12px!important}.w-md-13px{width:13px!important}.w-md-14px{width:14px!important}.w-md-15px{width:15px!important}.w-md-16px{width:16px!important}.w-md-17px{width:17px!important}.w-md-18px{width:18px!important}.w-md-19px{width:19px!important}.w-md-20px{width:20px!important}.w-md-21px{width:21px!important}.w-md-22px{width:22px!important}.w-md-23px{width:23px!important}
  .w-md-24px{width:24px!important}.w-md-40px{width:40px!important}
  .width-md-24{width:24rem!important}.width-md-23{width:23rem!important}.width-md-21{width:21rem!important}.width-md-20{width:20rem!important}.width-md-14{width:14rem!important}.width-md-13{width:13rem!important}.width-md-12{width:12rem!important}.width-md-11{width:11rem!important}.width-md-10{width:10rem!important}.width-md-9{width:9rem!important}.width-md-8{width:8rem!important}.width-md-7{width:7rem!important}.width-md-6{width:6rem!important}.width-md-5{width:5rem!important}.width-md-4{width:4rem!important}.width-md-3{width:3rem!important}.width-md-2{width:2rem!important}.width-md-1{width:1rem!important}
  .p-md-20 {padding: 20px !important;}
  .mb-md-0 {margin-bottom: 0 !important;}
  .mb-md-40 {margin-bottom: 40px !important;}
  .mb-md-60 {margin-bottom: 60px !important;}
  .mb-md-68 {margin-bottom: 68px !important;}
  .mt-md-16 {margin-top: 16px !important;}
  .mt-md-60 {margin-top: 60px !important;}
  .lh-md-13 {line-height: 1.3rem;} .lh-md-15 {line-height: 1.5rem;} .lh-md-20 {line-height: 2rem;}
  .lh-md-26px {line-height: 26px;}
  .modal-md { --cui-modal-width: 600px; }
  .pt-md-80 { padding-top: 80px !important;}
  .pb-md-80 { padding-top: 80px !important;}
  .px-md-0 {padding-left: 0 !important; padding-right: 0 !important;}
  .py-md-0 {padding-top: 0 !important; padding-bottom: 0 !important;}
  .border-radius-md-12 { border-radius: 12px !important; }

  .fw-md-300 { font-weight: 300 !important; }
  .fw-md-500 { font-weight: 500 !important; }
  .justify-content-md-center {justify-content: center;}
}
@media (max-width: 1160px) {
  .swiper-pagination span {width: 6px; height: 6px;}
  .swiper-btn-wrap {bottom: 0;right: 0;}
  .swiper-prev, .swiper-next {width: 36px; height: 36px;margin-left: 0.5rem;}
  .fs-sm-10 {font-size: 1.063rem;}
  .fs-sm-9 {font-size: 0.938rem;}
  .rs-popover {max-width: 12rem;}
  .px-sm-0px {padding-left: 0 !important; padding-right: 0 !important;}
  .px-sm-12 {padding-left: 12px !important; padding-right: 12px !important;}
  .w-sm-100 {width: 100% !important;}
  .w-sm-75 {width: 75% !important;}
  .w-sm-56px {width: 56px !important;}
  .flex-sm-1 {flex: 1}
  .text-sm-right {text-align: right;}
  .text-sm-center {text-align: center;}
  .mb-sm-12px { margin-bottom: 12px !important;}
  .hr-2 { border-top: 1px solid; }
  .btn { font-size: 16px; }
  .bg-sm-1 {background-color: #16171C !important;}
  .nav-tabs { 
    .nav-link, .nav-link:hover, .nav-link:focus{border:none;border-bottom: solid 4px transparent;color:#000 !important;font-weight:700;display:flex;align-items:center;justify-content:center;margin:0; }
  } 
  .modal .modal-dialog { margin: 20px; }
  .modal-footer {padding: 20px;
    button.one {width: 100%;}
  }
  .form-check-input[type=checkbox] {margin-top: 3px;}
  .form-check-input[type=radio], .radio .form-check-input[type=checkbox] {height: 18px; width: 18px; margin-top: 2px; }
  .form-control, .form-select, .rs-input { font-size: 14px !important; }
  .form-check-input[type=radio] { height: 18px; width: 18px; margin-top: 2px;}
  .form-check-label {cursor: pointer; font-size: 15px; }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction 
  { margin-top: 1px;}
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2); 
  }
  [data-tippy-root] { min-width: 100%; }
  ::-webkit-input-placeholder {font-size: 13px;}
  :-ms-input-placeholder {font-size: 13px;}
  ::placeholder {font-size: 13px;}
}
// 
/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.05); 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.3); 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.7); 
}

@media (min-width: 576px) {
  .modal-md .modal-dialog {
      max-width: 497px;
  }
}