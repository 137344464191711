.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
  .body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    &>div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
  }
}
