@keyframes mulShdSpin {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.1; }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes datePickerSlideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 300ms;
}

.fadeOut {
    animation-name: fadeOut;
    animation-duration: 300ms;
}

div:has(.spinner-border) {text-align: center; }
.data-wrap {
    display: flex; align-items: center;
    .data-label {width: 110px;}
    .data {margin-left: 10px; align-items: center; flex: 1;}
}
.container-lg {
    max-width: 1120px;
    position: relative;
}
.container-xl {
    width: 100%;
    &:not(.height-auto) {
        height: 100%;
    }
    max-width: 1440px;
    --cui-gutter-x: 0;
    .mobile-header {
        height: 15px;
    }
}
.container-xxl {
    max-width: 1660px;
    padding-left: 0;
    padding-right: 0;
}
.content-wrapper {
    margin-left: 60px;
    max-width: 1085px;
    --cui-gutter-x: 0;
    > .row {
        --cui-gutter-x: 0;
    }
}
.kakao-contact-box {
    position: fixed;
    max-width: 1440px;
    left: 50%;
    bottom: 0;
    z-index: 2;
    transform: translateX(-50%);
    .kakao-contact {
        position: absolute;
        right: 0;
        bottom: 60px;
        z-index: 1000;
        img {
            border-radius: 25px;
        }
    }
}

.menu-mask {display: none;}
hr {color: #000;}
// @media (hover: hover) and (pointer: fine) {
//     .btn:hover {background: inherit !important; color: inherit !important;}
// }

:target {
    scroll-margin-top: 98px;
    &#showroom {
        scroll-margin-top: 180px;
    }
}

.header { height: 98px; padding: 10px 15px;
    .container-fluid {max-width: 1440px;}
    .header-brand {font-size: 29px; color: black;}
    .header-nav {
        align-items: center;
        .nav-link { font-weight: 700; color: #333; font-size: 22px; margin: 5px 24px; padding: 0; position: relative;}
        .nav-link:hover {color: #9A9A9A;}
        .nav-link.active span {width: 100%; height: 2px; background-color: #000; display: inline-block; position: absolute; bottom: 0; left: 0;}
        button { height: 36px; width: 106px; border-radius: 10rem; font-size: 19px; padding: 2px; margin: 3px 24px; }
    } 
    .nav-item {
        position: relative;
        .sub-menu {position:absolute;background:#fff;z-index:99;left:0;top:70px;border:1px solid #eee;padding: 21px;color:#000;font-family:Pretendard, sans-serif;font-size:20px;font-style:normal;font-weight:700; transition:.3s ease-out; display: none; min-width: 245px;
            li {padding: 3px; cursor: pointer; word-break: break-all;}
            li.dummy { position: absolute; width: 100%; height: 37px; top: -35px; left: 0; cursor: default;}
            div.active {text-decoration: underline;}
            .sub-sub-menu {font-size: 18px; font-weight: 500; display: none;}
            li.on .sub-sub-menu {display: block;}
        }
        /* 자재파인더 */
        &.material-finder {
            margin-right: 60px;
            width: 185px;
            height: 54px;
            font-size: 22px;
            position: relative;
            .btn-dark {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 185px;
                height: 54px;
                font-weight: 700;
                background: rgb(51, 51, 51);
                &:hover {
                    background: rgb(51, 51, 51) !important;
                }
                &:active {
                    background: rgb(0, 0, 0);
                }
                &:disabled {
                    background: rgb(183, 183, 183);
                }
                span::before {
                    content: '';
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                    background: url('../assets/img/icn_Camera.svg') no-repeat center;
                    background-size: cover;
                    vertical-align: middle;
                }
            }
            .popover {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: -106px;
                top: 74px;
                width: 270px;
                height: 46px;
                font-size: 16px;
                font-weight: 700;
                font-family: Pretendard, sans-serif;
                line-height: 19.09px;
                text-align: center;
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                color: #fff;
                border: 0 none;
                border-radius: 500px;
                background: rgb(0, 199, 60);
                /* Arrow (16x11) */
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -11px;
                    left: 26px;
                    width: 15px;
                    height: 11px;
                    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMEwxNSAxMUgwTDggMFoiIGZpbGw9IiMwMEM3M0MiLz4KPC9zdmc+");
                }
                .btn-close {
                    margin: 0 0 0 8px;
                    padding: 0;
                    width: 16px;
                    height: 16px;
                    background: url('../assets/img/icon_x_white.svg') no-repeat center / 16px 16px;
                    opacity: 1;
                }
            }
        }
        /* 장바구니*/
        &.cart {
            width: 30px;
            height: 30px;
            background: url('../assets/img/icn_cart.svg') no-repeat center;
            .nav-link {
                margin: 0;
                display: block;
                height: 100%;
                .sound-only {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    border: 0;
                }

            }
        }
    }
    .nav-item:hover .sub-menu {display: block;}
}
.header-pop {

    background-color: #00C73C; border-radius: 10rem;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    .popover-body { font-size: 16px; font-weight: 700; padding: 10px 20px; color: #fff; }
    .popover-arrow::after, .popover-arrow::after {border-bottom-color: #00C73C !important;}
    .popover-arrow::before, .popover-arrow::before {border-bottom-color: #00C73C !important; top: 1px;}
}
.footer {
    display: block; padding: 36px; background-color: #000; text-align: center; justify-content: center; font-size: 13px; color: white; white-space: pre-wrap; line-height: 20px;
}
.modal-meterial-finder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    &:not(.is-uploading) .modal-body .drop-zone {
        flex-direction: column;
        background: url('../assets/img/img_finder.svg') no-repeat 60px center / 80px 80px;
        &.error {
            padding-top: 60px;
            background-position-y: 87.5px;
        }
    }
    &.is-uploading .modal-content .modal-body .drop-zone {
        padding-left: 24px;
        padding-right: 24px;
    }
    /* Backdrop */
    &::after {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10000;
    }
    .modal-content {
        width: 710px;
        height: 333px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 10001;
        header {
            height: 68px;
            padding: 22px 30px 28px 20px;
            display: flex;
            justify-content: space-between;
            color: #fff;
            background: rgb(32, 166, 76);
            border-radius: 12px 12px 0 0;
            box-sizing: border-box;
            h2 {
                margin-bottom: 0;
                height: 24px;
                display: flex;
                align-items: center;
                font-size: 20px;
                font-weight: 700;
                line-height: 23.87px;
                >small {
                    margin-left: 12px;
                    font-size: 16px;
                    line-height: 19.09px;
                }
            }
            .close-btn {
                width: 20px;
                height: 20px;
                margin: 0;
                padding: 0;
                background: transparent url('../assets/img/icon_close.svg') no-repeat center / 20px 20px;
                border: 0 none;
                /* Screen reader only */
                >span {
                    font-size: 0;
                    letter-spacing: -9999px;
                    text-indent: -9999px;
                }
            }
        }
        .modal-body {
            padding: 30px;
            border-radius: 0 0 12px 12px;
            .drop-zone {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding: 0 0 0 139px;
                width: 650px;
                height: 205px;
                border: 2px dashed rgb(32, 166, 76);
                border-radius: 12px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.09px;
                color: rgba(153, 153, 153, 1);
                text-align: center;
                user-select: none;
                /*-webkit-user-drag: none;*/
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                position: relative;
                label {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                &.dragging::after {
                    width: 100%;
                    height: 100%;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    background: rgba(26, 69, 40, 0.3);
                    border-radius: 12px;
                }
                .uploader-error {
                    width: 100%;
                    height: 60px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    padding: 12px;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    background-color: rgba(255, 0, 0, 0.06);
                    border-radius: 12px 12px 0 0;
                    color: rgb(74, 49, 49);
                    b {
                        &::before {
                            margin-right: 6px;
                            content: '';
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            background: url('../assets/img/icon_alert.svg') no-repeat center / cover;
                            vertical-align: middle;
                        }
                        font-weight: 600 !important;
                        color: rgb(255, 89, 89);
                    }
                }
                input[type=file] {
                    display: none;
                }
                button {
                    padding: 0;
                    display: inline-flex;
                    background: transparent;
                    border: 0 none;
                    font-size: 16px;
                    font-weight: 700;
                    text-decoration: underline;
                    color: rgba(32, 166, 76, 1);
                }
                em {
                    margin-top: 24px;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 16.71px;
                    color: rgb(51, 51, 51);
                    letter-spacing: -0.03em;
                }
            }
            .uploader-status {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100px;
                margin-top: 60px;
                padding: 20px 20px 20px 89px;
                background: rgba(50, 50, 50, 0.03) url('../assets/img/icn_image.svg') no-repeat 20px center / 44px 44px;
                border-radius: 20px;
                box-sizing: border-box;
                .filename {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    font-weight: 600;
                    color: rgb(51, 51, 51);
                }
                .progress {
                    position: relative;
                    height: 4px;
                    background: rgb(217, 217, 217);
                    border-radius: 50px;
                    overflow: hidden;
                    .progress-bar {
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: rgb(32, 166, 76);
                        height: 100%;
                        border-radius: 50px;
                    }
                }
                .uploader-progress {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;
                    .percent {
                        color: rgba(32, 166, 76, 1);
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.loading {
    &.fullscreen {
        position: fixed;
        left: 0;
        top: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        &::after {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 10000;
        }

    }
    margin-top: 200px;
    margin-bottom: 401px;
    position: relative;
    .loading-icon {
        display: flex;
        align-items: center;
        width: 40px;
        height: 40px;
        background: rgb(32, 166, 76);
        border-radius: 8px;
        margin: 0 auto;
        position: relative;
        z-index: 10001;
        .icon-area {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            .spinner {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                display: block;
                path {
                    animation-name: mulShdSpin;
                    animation-duration: 1.2s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            animation-delay: #{0.075 * ($i - 1)}s;
                        }
                    }
                }
            }
        }
    }
    .loading-text {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        z-index: 10001;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    z-index: 99999999;
}

.modal-meterial-finder .modal-body {
    background: #fff;
}
.mobile-header {display: none;}
.btn-green {background-color: #00C73C !important;}
.joinus-modal + div {backdrop-filter: blur(5px);}
.accordion {
    .accordion-item {border-radius: 0 !important; background-color: transparent; border: none; }
    .accordion-button { border-radius: 0 !important; border: none; color: #000; outline:0;box-shadow:none !important; }
    .accordion-body {
        color: #000;
        /* 에디터 기본 스타일 초기화 */
        p,
        ol,
        ul,
        pre,
        blockquote,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
            padding: 0;
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        }
        ul {
            list-style: disc;
        }
        ol,
        ul {
            padding-left: 1.5em;
        }
        ol > li,
        ul > li {
            list-style-type: none;
        }
        ul>li:before {
            content: '\2022';
        }
        ol {
            list-style: decimal;
        }
        li::before {
            display: inline-block;
            white-space: nowrap;
            width: 1.2em;
        }
        li:not(.ql-direction-rtl)::before {
            margin-left: -1.5em;
            margin-right: 0.3em;
            text-align: right;
        }
        li.ql-direction-rtl::before {
            margin-left: 0.3em;
            margin-right: -1.5em;
        }
        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {
            padding-left: 1.5em;
        }
        ol li.ql-direction-rtl,
        ul li.ql-direction-rtl {
            padding-right: 1.5em;
        }
        ol li {
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
            counter-increment: list-0;
        }
        ol li:before {
            content: counter(list-0, decimal) '. ';
        }
        ol li.ql-indent-1 {
            counter-increment: list-1;
        }
        ol li.ql-indent-1:before {
            content: counter(list-1, lower-alpha) '. ';
        }
        ol li.ql-indent-1 {
            counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        }
        ol li.ql-indent-2 {
            counter-increment: list-2;
        }
        ol li.ql-indent-2:before {
            content: counter(list-2, lower-roman) '. ';
        }
        ol li.ql-indent-2 {
            counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        }
        ol li.ql-indent-3 {
            counter-increment: list-3;
        }
        ol li.ql-indent-3:before {
            content: counter(list-3, decimal) '. ';
        }
        ol li.ql-indent-3 {
            counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
        }
        ol li.ql-indent-4 {
            counter-increment: list-4;
        }
        ol li.ql-indent-4:before {
            content: counter(list-4, lower-alpha) '. ';
        }
        ol li.ql-indent-4 {
            counter-reset: list-5 list-6 list-7 list-8 list-9;
        }
        ol li.ql-indent-5 {
            counter-increment: list-5;
        }
        ol li.ql-indent-5:before {
            content: counter(list-5, lower-roman) '. ';
        }
        ol li.ql-indent-5 {
            counter-reset: list-6 list-7 list-8 list-9;
        }
        ol li.ql-indent-6 {
            counter-increment: list-6;
        }
        ol li.ql-indent-6:before {
            content: counter(list-6, decimal) '. ';
        }
        ol li.ql-indent-6 {
            counter-reset: list-7 list-8 list-9;
        }
        ol li.ql-indent-7 {
            counter-increment: list-7;
        }
        ol li.ql-indent-7:before {
            content: counter(list-7, lower-alpha) '. ';
        }
        ol li.ql-indent-7 {
            counter-reset: list-8 list-9;
        }
        ol li.ql-indent-8 {
            counter-increment: list-8;
        }
        ol li.ql-indent-8:before {
            content: counter(list-8, lower-roman) '. ';
        }
        ol li.ql-indent-8 {
            counter-reset: list-9;
        }
        ol li.ql-indent-9 {
            counter-increment: list-9;
        }
        ol li.ql-indent-9:before {
            content: counter(list-9, decimal) '. ';
        }
        .ql-indent-1:not(.ql-direction-rtl) {
            padding-left: 3em;
        }
        li.ql-indent-1:not(.ql-direction-rtl) {
            padding-left: 4.5em;
        }
        .ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-right: 3em;
        }
        li.ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-right: 4.5em;
        }
        .ql-indent-2:not(.ql-direction-rtl) {
            padding-left: 6em;
        }
        li.ql-indent-2:not(.ql-direction-rtl) {
            padding-left: 7.5em;
        }
        .ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-right: 6em;
        }
        li.ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-right: 7.5em;
        }
        .ql-indent-3:not(.ql-direction-rtl) {
            padding-left: 9em;
        }
        li.ql-indent-3:not(.ql-direction-rtl) {
            padding-left: 10.5em;
        }
        .ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-right: 9em;
        }
        li.ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-right: 10.5em;
        }
        .ql-indent-4:not(.ql-direction-rtl) {
            padding-left: 12em;
        }
        li.ql-indent-4:not(.ql-direction-rtl) {
            padding-left: 13.5em;
        }
        .ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-right: 12em;
        }
        li.ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-right: 13.5em;
        }
        .ql-indent-5:not(.ql-direction-rtl) {
            padding-left: 15em;
        }
        li.ql-indent-5:not(.ql-direction-rtl) {
            padding-left: 16.5em;
        }
        .ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-right: 15em;
        }
        li.ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-right: 16.5em;
        }
        .ql-indent-6:not(.ql-direction-rtl) {
            padding-left: 18em;
        }
        li.ql-indent-6:not(.ql-direction-rtl) {
            padding-left: 19.5em;
        }
        .ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-right: 18em;
        }
        li.ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-right: 19.5em;
        }
        .ql-indent-7:not(.ql-direction-rtl) {
            padding-left: 21em;
        }
        li.ql-indent-7:not(.ql-direction-rtl) {
            padding-left: 22.5em;
        }
        .ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-right: 21em;
        }
        li.ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-right: 22.5em;
        }
        .ql-indent-8:not(.ql-direction-rtl) {
            padding-left: 24em;
        }
        li.ql-indent-8:not(.ql-direction-rtl) {
            padding-left: 25.5em;
        }
        .ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-right: 24em;
        }
        li.ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-right: 25.5em;
        }
        .ql-indent-9:not(.ql-direction-rtl) {
            padding-left: 27em;
        }
        li.ql-indent-9:not(.ql-direction-rtl) {
            padding-left: 28.5em;
        }
        .ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-right: 27em;
        }
        li.ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-right: 28.5em;
        }
        .ql-video {
            display: block;
            max-width: 100%;
        }
        .ql-video.ql-align-center {
            margin: 0 auto;
        }
        .ql-video.ql-align-right {
            margin: 0 0 0 auto;
        }
        .ql-bg-black {
            background-color: #000;
        }
        .ql-bg-red {
            background-color: #e60000;
        }
        .ql-bg-orange {
            background-color: #f90;
        }
        .ql-bg-yellow {
            background-color: #ff0;
        }
        .ql-bg-green {
            background-color: #008a00;
        }
        .ql-bg-blue {
            background-color: #06c;
        }
        .ql-bg-purple {
            background-color: #93f;
        }
        .ql-color-white {
            color: #fff;
        }
        .ql-color-red {
            color: #e60000;
        }
        .ql-color-orange {
            color: #f90;
        }
        .ql-color-yellow {
            color: #ff0;
        }
        .ql-color-green {
            color: #008a00;
        }
        .ql-color-blue {
            color: #06c;
        }
        .ql-color-purple {
            color: #93f;
        }
        .ql-font-serif {
            font-family: Georgia, Times New Roman, serif;
        }
        .ql-font-monospace {
            font-family: Monaco, Courier New, monospace;
        }
        .ql-size-small {
            font-size: 0.75em;
        }
        .ql-size-large {
            font-size: 1.5em;
        }
        .ql-size-huge {
            font-size: 2.5em;
        }
        .ql-direction-rtl {
            direction: rtl;
            text-align: inherit;
        }
        .ql-align-center {
            text-align: center;
        }
        .ql-align-justify {
            text-align: justify;
        }
        .ql-align-right {
            text-align: right;
        }
        .quill-hr {
            &.line1 {
                margin: 30px auto 29px;
                width: 220px;
                height: 1px;
                background-color: #ddd;
            }
            &.line2 {
                margin: 30px auto 29px;
                width: 100%;
                height: 1px;
                background-color: #ddd;
            }
            &.line3 {
                margin: 28px auto 29px;
                width: 67px;
                height: 3px;
                background-color: #333;
            }
            &.line4 {
                margin: 29px auto 23px;
                width: 238px;
                height: 9px;
                background-image: url(../assets/img/editr-sprite.png);
                background-size: 1107px 1077px;
                background-position: -362px -329px;
            }
            &.line5 {
                margin: 19px auto;
                width: 192px;
                height: 23px;
                background-image: url(../assets/img/editr-sprite.png);
                background-size: 1107px 1077px;
                background-position: -316px -466px;
            }
            &.line6 {
                margin: 28px auto 26px;
                width: 66px;
                height: 6px;
                background-image: url(../assets/img/editr-sprite.png);
                background-size: 1107px 1077px;
                background-position: -426px -389px;
            }
            &.line7 {
                margin: 8px auto;
                width: 44px;
                height: 44px;
                background-image: url(../assets/img/editr-sprite.png);
                background-size: 1107px 1077px;
                background-position: -709px -671px;
            }
            &.line8 {
                margin: 0 auto;
                width: 2px;
                height: 60px;
                vertical-align: top;
                background-color: #aaa;
            }
        }
    }
}

.login {
    input { border: none; ::placeholder {font-size: 15px; font-weight: 300; } }
    .btn-sns {
        display: flex; padding: 8px !important; align-items: center; justify-content: center; border-radius: 4px; flex: 1; border: none;
        .img-wrap {width: 50px; padding-right: 7px; padding-bottom: 4px;}
        .txt-wrap {padding: 2px 0; display: flex; align-items: center; justify-content: center; font-size: 14px; border-left: 1px solid; flex: 1;}
    }
    .btn-naver {
        background-color: #2DB400 !important; flex: 1;
        .txt-wrap {color: white;}
    } 
    .btn-naver + button {flex: 1}
    .btn-kakao {
        background-color: #FFE500 !important;
        .txt-wrap {color: black;}
    } 
    .form-label {position: absolute; top: -2px; right: 10px; color: red; width: 40px;}
    .cert-input.form-control.is-invalid {background: none !important;}
}
.register {
    .btn-sns {height: 56px;}
    .item {
        padding: 18px; border-radius: 4px; border: 4px solid transparent; background-color: white; cursor: pointer; font-weight: 700;
        img {width: 171px;}
    }
    .item.on { border: 4px solid black; }
    .btn-email {
        background-color: #000 !important;
        .txt-wrap {color: white;}
    } 
    .data-wrap { align-items: flex-start;
        .data-label {width: 165px; font-size: 18px;}
        .data { display: flex; width: 100%; flex: 1;
            .buss_img {
                text-align: center;
                border: 1px solid #000;
                img {
                    width: 46px;
                    height: auto;
                }
            }
            .btn {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: fit-content;
                height: 32px;
                font-size: 16px !important;
                padding: 8px 12px;
                img {
                    margin-left: 7px;
                }
                &.btn-zoom-in {
                    padding: 0;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    bottom: -8px;
                    right: -12px;
                    border: 0 none;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .no-data {color: #9A9A9A; font-size: 18px; border: 0.5px solid #000; width: 100%; height: 242px; display: flex; align-items: center; justify-content: center;}
        }
    }
    .form-control, .form-select {height: 32px; border: 0.5px solid #000; border-radius: 0;}
    .form-select {padding: 0 10px !important;}
}

.home {
    width: 100vw;
    background-color: #E5E6E1;
    .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 900px;
        background: url('../assets/img/main_banner.webp') no-repeat center / cover;
        position: relative;
        .banner-text {
            letter-spacing: -0.9px;
            line-height: 46px;
            position: absolute;
            top: 358px;
            left: 50%;
            transform: translateX(-50%);
        }
        .btn-area {
            margin-top: 50px;
            .btn {
                width: 244px;
                height: 54px;
                --cui-btn-font-size: 20px;
                --cui-btn-font-weight: 600;
                --cui-btn-border-radius: 6px;
                letter-spacing: -1.8px;
                border: 0 none;
                background-color: rgb(51, 51, 51) !important;
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
                justify-self: center;
            }
        }
    }
    section {height: 100vh; padding-top: 110px;}
    .fs-115 {font-size: 115px; }
    h1.fs-115 {font-size: 115px; font-weight: 900;}
    .map-wrap {
        display: flex;
        flex-direction: column;
    }
    .page-link {font-size: 24px;}
    .pagination .w-10px {font-size: 14px !important;}

    .story {
        height: 1065px;
        background: rgba(32, 166, 76, 0.06);
        .story-card {
            min-height: 251px;
            box-shadow: 0 0 30px 0 rgba(32, 166, 76, 0.1);
        }
        .row {
            --cui-gutter-x: 30px;
        }

        .bg-success {
            height: 168px;
            box-shadow: 0 0 30px 0 rgba(16, 77, 36, 0.2);
            span {
                position: relative;
                &::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    display: block;
                    border-radius: 50%;
                    background: #fff;
                    position: absolute;
                    left: 50%;
                    top: -6px;
                }
            }
        }
    }
    .showroom {
        height: 953px;
    }
    .portfolio {
        height: 914px;
        background: #F8F8F8;
        .swiper {
            margin-top: 10px;
            height: 612px;
            overflow-y: visible;
            .swiper-slide {
                width: 1000px !important;
                height: 652px;
                font-size: 16px;
                font-weight: 400;
                line-height: 34px;
                letter-spacing: -0.5px;
                text-align: center;
                img {
                    width: 100%;
                    height: 560px;
                    display: block;
                    margin-bottom: 18px;
                }
            }
        }
        .swiper-horizontal>.swiper-pagination-bullets {
            --swiper-pagination-top: -45px;
            --swiper-pagination-bottom: auto;
            position: absolute;
        }
        .swiper-button-prev,
        .swiper-button-next {
            margin-top: 0 !important;
            position: absolute;
            top: 0 !important;
            width: 161px !important;
            height: 560px !important;
            &::before {
                content: '';
                display: block;
                width: 40px !important;
                height: 40px !important;
                position: absolute;
                top: 50%;
                box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
                transform: translateY(-50%);
            }
        }
        .swiper-button-prev {
            left: 0 !important;
            background: linear-gradient(270deg, rgba(248, 248, 248, 0) -0.38%, #F8F8F8 89.38%);
            &::before {
                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNDAiIHk9IjQwIiB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHJ4PSIyMCIgdHJhbnNmb3JtPSJyb3RhdGUoLTE4MCA0MCA0MCkiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0yMi41IDI4LjA2MDVMMTQuNSAxOS45OTk5TDIyLjUgMTEuOTM5MyIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") no-repeat center / cover;
                left: 109px;
            }
        }
        .swiper-button-next {
            right: 0 !important;
            background: linear-gradient(270deg, #F8F8F8 9.7%, rgba(248, 248, 248, 0) 99.62%);;
            &::before {
                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiByeD0iMjAiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xNy41IDExLjkzOTVMMjUuNSAyMC4wMDAxTDE3LjUgMjguMDYwNyIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat center / cover;
                right: 109px;
            }
        }
        .swiper-pagination .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            background-color: rgb(217, 217, 217);
        }
        .swiper-pagination .swiper-pagination-bullet-active {
            width: 8px;
            background-color: $success !important;
        }
    }
}

.tiles {
    display: flex;
    .filter-wrap {
        border: 1px solid #E5E6E1; width: 222px; border-top: none;
        .form-check-input[type=checkbox] {margin-top: 4px;}
        .form-check-label {font-size: 17px; letter-spacing: -1px;}
    }
    .accordion {
        .accordion-item {border-radius: 0 !important; background-color: transparent; border: none; border-bottom: 1px solid #E5E6E1;}
        .accordion-button { border-radius: 0; border: none; background-color: transparent; outline:0;box-shadow:none !important; font-size: 18px; font-weight: 700; padding: 20px 25px; color: black;}
        .accordion-body {padding: 0 24px 10px;
            .form-check {margin-bottom: .25rem;}
        }
    }
    .search-wrap {
        padding: 32px; flex: 1;
    }
    .keyword-wrap {
        display: flex; align-items: flex-start;
        .form-control {border-radius: 10rem; flex: 1; padding: 4px 20px;}
        .select-wrap { 
            display: flex;
            .dropdown {
                button{width:140px;text-align:left;border:none;border-bottom:1px solid;border-radius:0;font-size:18px;color:#000; padding: 5px;}
                button::after{flex-shrink:0;width:14px;height:8px;margin-left:auto;content:"";background-image:url(../assets/img/i_down.png);background-repeat:no-repeat;background-size:cover;transition:.3s ease-out;display:inline-flex;position:absolute;right:6px;top:19px}
                button:hover {background-color: transparent; color: #000;}
            }
            .show {
                button::after {transform: rotate(-180deg);}
            }
            .dropdown-menu {padding: 0; border: 1px solid; min-width: 140px; margin-top: -3px !important;
                .dropdown-item { font-size: 18px; padding: 4px 10px; border-bottom: 1px solid; color: black; }
                li:last-child .dropdown-item {border-bottom: none;}
                li:hover {background-color: #E5E6E1;}
            }
        }
        .key-wrap {
            display: flex; flex-wrap: wrap; margin-top: 8px;
            .keyword-item { height: 37px; background-color: #00C73C; padding: 5px 15px 5px 10px; border-radius: 10rem; display: flex; align-items: center; color: white; font-size: 16px; margin: 0 10px 5px 0; }
            .i_close {width: 19px; margin-left: 5px; cursor: pointer;}
            .btn-clear {background-color: #9A9A9A;}
        }
    }
    .list-wrap {
        display: flex; flex-wrap: wrap; column-gap: 28px; row-gap: 60px;
        .tile-item {flex: 1 1 22%; max-width: 23.6%; position: relative;
            .checkbox {
                position: absolute;
                left: 18px;
                top: 18px;
                width: 32px;
                height: 32px;
                input[type=checkbox] {
                    width: 100%;
                    height: 100%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background: #fff;
                    border: 2px solid rgb(51,51,51);
                    border-radius: 8px;
                    box-sizing: border-box;
                    &:checked {
                        background: #fff url(../assets/img/icn_checked_black.svg) no-repeat center / 21px 16px;
                    }
                }
            }
            .action {
                display: flex;
                column-gap: 10px;
                position: absolute;
                right: 10px;
                top: 228px;
                .btn {
                    margin: 0;
                    padding: 0;
                    border: 0 none;
                    span {
                        visibility: hidden;
                        font-size: 0;
                        letter-spacing: -9999px;
                        word-spacing: -9999px;
                        color: transparent;
                    }
                }
                .wishlist {
                    width: 30px;
                    height: 30px;
                    background: transparent url('../assets/img/btn_star.svg') no-repeat center / cover;
                    &.on {
                        background-image: url('../assets/img/btn_star_on.svg');
                    }
                }
                .cart {
                    width: 30px;
                    height: 30px;
                    background: transparent url('../assets/img/btn_cart.svg') no-repeat center / cover;
                    &.on {
                        background-image: url('../assets/img/btn_cart_on.svg');
                    }
                }
            }
            .icon-favorite-off {
                display: block;
                width: 30px;
                height: 30px;
                background: url('../assets/img/icon_favorite_off.svg') no-repeat center / cover;
            }
            .icon-favorite-on {
                display: block;
                width: 30px;
                height: 30px;
                background: url('../assets/img/icon_favorite_on.svg') no-repeat center / cover;
            }
            .thumb {width: 100%; aspect-ratio: 1; background-size: cover; background-repeat: no-repeat; background-position: center;}
            hr {
                --cui-border-width: 2px;
                color: rgb(51, 51, 51);
            }
            .product-name {
                margin-top: 26px;
            }
            .spec {
                margin-right: 10px;
                font-weight: 400 !important;
            }
            .normal-price {
                font-weight: 500 !important;
                color: rgb(153, 153, 153);
            }
            .price {
                margin-right: 2px;
                font-size: 20px !important;
                font-weight: 800 !important;
            }
            .sale {
                margin-right: 6px;
            }
        }
    }
    .excel-table {
        border-color: #E5E6E1; border-top-color: #000;
        :not(caption) > * { border-bottom-color: black; }
        td {padding: 0 !important;}
        td.no-data {padding: 1rem !important;}
        th div {
            display: flex; align-items: center; justify-content: center; transition:.3s ease-out; cursor: pointer;
            img {margin: 0 3px; opacity: 0; width: 7px;transition:.2s ease-out;}
            span{background-color:rgba(0,0,0,.12);padding:2px;border-radius:100%;width:14px;height:14px;display:flex;align-items:center;justify-content:center;font-size:11px; line-height: 20px;}
        }
        th:hover img {opacity: 0.6;}
        th.on img {opacity: 1;}
        th.rotate img {transform: rotate(-180deg);}
        .thumb {background-size: cover; width: 70px; height: 70px; display: inline-block; background-position: center; background-repeat: no-repeat;}
    } 
    .hover-table { position: absolute; top: -17px; left: -195px; background-color: white; border-top: 1px solid; border-left: 1px solid; display: none; width: 200px; font-weight: 700;
        tr {border-bottom: 1px solid;}
        th, td {border-right: 1px solid; padding: 6px 12px !important;}
        td.on {background-color: #06a64c !important;}
    }
    .usage-wrap:hover .hover-table {display: block;}
}
.filter-modal {
    .modal-dialog { margin: 66px 20vw 0 0; }
}

.detail {
    .swiper-wrap {
        .thumb {
            width: 100%; height: 600px; background-position: center; background-repeat: no-repeat; background-size: cover;
        }
        .thumb-swiper .thumb { height: 100px; }
    }
    .unit-tab {
        border-radius: 10rem; overflow: hidden; display: flex; cursor: pointer;
        li {padding: 7px; background-color: #E5E6E1; width: 70px; text-align: center; }
        li.on { background-color: rgba(0, 199, 60, 0.30); }
    }
    .accordion {
        .accordion-button { background-color: #E5E6E1; padding: 11px 16px;}
        .accordion-body {padding: 14px;}
        .tile-item .thumb { width: 100%; aspect-ratio: 1; background-size: cover; background-repeat: no-repeat; background-position: center; }
    }
    .my-product {
        .swiper-button-prev, .swiper-button-next {top: 34% !important;}
    }
    .guide-accordion {
        .accordion-button::after {background-image: url(../assets/img/i_question.png);}
        .accordion-button:not(.collapsed)::after {transform: none;}
    }
    table.use-info { color: #000; border-bottom: 1px solid;
        th, td { padding: 9px !important; font-size: 18px; border-right: 1px solid rgba(0,0,0,.25); }
        th {border-bottom: 1px solid; border-top: 1px solid; background-color: #E5E6E1 !important;}
        th:first-child, td:first-child {border-right: 1px solid;}
        th:last-child, td:last-child {border-right: none;}
    }
    .order-calc {
        background-color: #E5E6E1; padding: 24px;
    }
    .sm-ctrl {width: 77px;}
    .square-calc {
        background-color: #E5E6E1;
        .list {padding: 19px 24px; display: flex; align-items: center;}
        hr {margin: 0 24px;}
    }
    .mix-blend-mode {background-color: #E5E6E1; mix-blend-mode: multiply;}
}

.buss_img_modal {
    --cui-modal-border-radius: 20px;
    --cui-modal-width: 1076px;
    --cui-modal-header-padding: 40px 0 14px;
    .modal-dialog {
        max-width: var(--cui-modal-width);
        .modal-content {
            border-radius: var(--cui-modal-border-radius);
            .modal-body {
                --cui-modal-padding: 0 26px;
                padding: var(--cui-modal-padding);
            }
            .img-wrap {
                .no-image {
                    background: #E5E5E5;
                    color: #999999;
                }
                height: 400px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 7px;
                    height: 7px;
                    background: transparent;
                }
                &::-webkit-scrollbar-track {
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    background: #BCBCBC;
                    border-radius: 13px;
                }
            }
            .modal-footer {
                display: flex;
                column-gap: 10px;
                .btn {
                    width: 100%;
                    flex-shrink: 1;
                }
            }
        }
    }
    .modal-header {
        .btn-close {
            display: none;
        }
    }

}
.buy-question-modal {
    ul {
        display: flex; flex-wrap: wrap;
        li {padding: 18px; width: calc(100% / 2); border-bottom: 1px solid #000;}
        li:nth-child(odd) { border-right: 1px solid #000;}
        li:last-child, li:nth-last-child(2) { border-bottom: none;}
        div {white-space: pre-wrap;}
        .customer-center {
            background: #E5E6E1; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25); padding: 12px;
        }
    }
}
// mypage
.mypage {
    height: 100%;
    .banner-wrap {
        height: 160px;
        padding: 41px 0; background: rgb(65, 63, 64); display: flex; align-items: center; justify-content: center; position: relative; text-align: center;
        color: #fff;
        font-size: 22px;
        font-weight: 400;
        .benifit-wrap {
            margin: 18px auto 0 auto;
            width: 208px;
            position: relative; text-align: center;
            font-size: 16px;
            font-weight: 400;
            .btn {
                border-radius: 30px;
                height: 34px;
                background-color: white;
                font-size: 14px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000 !important;
                &::after {
                    margin-left: 10px;
                    width: 5px;
                    height: 10px;
                    content: '';
                    display: inline-flex;
                    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDcgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMUw1LjUgNkwwLjUgMTEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS4yNSIvPgo8L3N2Zz4=") no-repeat center / cover;
                }
            }
        }
        .status-wrap {display: none;}
    }
    .side {
        height: 100%;
    }
    .menu-wrap {
        padding: 79px 32px; font-size: 18px; border: 2px solid #E5E6E1; border-top: none; width: 295px;
        height: 100%;
        box-sizing: border-box;
        hr {margin: 34px 0;color: rgb(216, 219, 224)}
        ul {
            li {margin-bottom: 10px;
                font-size: 20px;
                font-weight: 400;
                .active, :hover {font-weight: 500;}
                img {display: none;}
                a { color: rgb(51, 51, 51) }
                strong { font-size: 22px; font-weight: 600 }
            }
            li:last-child {margin-bottom: 0;}
        }
    }
    table {
        th { height: 81px; padding: 0 !important }
        td { padding: 30px 0 !important }
        th, td {font-size: 18px }
        th {font-weight: 700; background-color: transparent !important; border-top: 2px solid; border-bottom: 2px solid #000 !important;}
        td {border-bottom: 0.5px solid;}
    }
    .page-title {
        h4 { margin: 0 0 12px; font-size: 24px; font-weight: 700}
        b {
            display: inline-block;
            margin-right: 15px;
        }
        &.page-title-sm {
            h4 {
                font-size: 24px;
            }
        }
        span {margin-left: 10px; font-size: 22px; font-weight: 500;}
        p {
            margin-bottom: 55px;
            color: rgb(255, 89, 89);
            font-size: 16px;
            font-weight: 600;
        }
    }
    &.wishlist {
        .table {
            thead {
                th {
                    &.check {
                        width: 75px;
                    }
                }
            }
        }
    }

    &.wishlist,
    &.cart {
        align-items: stretch;
        flex-direction: column;
        .table {
            thead {
                height: 81px;
                color: rgb(119, 119, 119);
                font-weight: 600;
                th {
                    border-bottom: 1px solid rgb(221, 221, 221) !important;
                }
            }
            th,
            td {
                &.cat-name {
                    width: 170px;
                }
                &.product-name {
                    width: 185px;
                }
                &.color {
                    width: 170px;
                }
                &.stock {
                    width: 115px;
                }
                &.action {
                    width: 110px;
                }
            }
            td {
                font-size: 18px;
                font-weight: 500;
                padding: 20px 0 !important;
                vertical-align: middle;
                .thumb {
                    text-align: center;
                    width: 140px;
                    height: 100px;
                }
                .stock + .btn {
                    font-size: 14px !important;
                    font-weight: 500;
                    border-radius: 6px;
                    height: 34px;
                }
            }
            thead {
                display: table;
                width: 100%;
                table-layout: fixed;
            }
            tbody {
                display: block;
                table-layout: fixed;
                width: calc(100% + 10px);
                max-height: 840px;
                overflow-x: hidden;
                overflow-y: scroll;
                scroll-behavior: smooth;
                padding-right: 10px;
            }
        }
        .input-group {
            &.stock {
                margin-left: auto;
                margin-right: auto;
                border-color: rgb(217, 217, 217);
                .btn {
                    padding: 0 !important;
                    border-radius: 0 !important;
                    background: #ffffff !important;
                    color: rgba(193, 193, 193, 1);
                    border-color: rgb(217, 217, 217);
                }
                .form-control {
                    width: 35px;
                    padding: 0 !important;
                    border: 1px solid rgb(217, 217, 217) !important;
                    text-align: center;
                    color: rgb(51, 51, 51) !important;
                    font-size: 18px !important;
                }
            }
        }
        .btn-cart {
            --cui-border-radius: 10px;
            --cui-btn-font-size: 18px;
            width: 254px;
            height: 50px;
        }
        .btn-delete {
            background: transparent url('../assets/img/icn_delete_16x16_dark.svg') no-repeat center / 16px 16px;
        }
    }
}
.member-info {
    .data-wrap .data  .btn-save {position: absolute; right: 0;display: block; height: 46px; width: 11rem;}
    .paybag-wrap {padding: 3.5rem 110px; display: table;}
}
.member-lv {
    .request-wrap { 
        display: flex;
        .request {display: flex; align-items: center; justify-content: space-between;background: #E5E6E1; padding: 20px 32px; flex: 1; cursor: pointer;}
        .request.on {background: #000; color: white;}
    }
}
.faq {
    .tab-wrap {
        border-top: 2px solid; border-bottom: 2px solid; padding: 16px 0; display: flex;
        li {border-right: 0.5px solid; flex: 1;text-align: center; padding: 10px; font-size: 18px; cursor: pointer;}
        li:last-child {border: none;}
        li.on {font-weight: 700;}
    }
}

@media (max-width: 992px) {
    .header {
        .header-nav {
            .nav-link {margin: 5px 10px;}
            .button {margin: 3px 10px; width: 100px;}
        }
    } 
}

.material-finder {
    .container-xl {
        --cui-gutter-x: 48px;
    }
}

.material-finder-list {
    .page-title {
        display: flex;
        align-items: center;
    }
    .checkbox {
        position: relative;
        width: 32px;
        height: 32px;
        input[type=checkbox] {
            width: 100%;
            height: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: #fff;
            border: 2px solid rgb(51, 51, 51);
            border-radius: 8px;
            box-sizing: border-box;
            &:checked {
                background: #fff url(../assets/img/icn_checked_black.svg) no-repeat center / 12px 10px;
            }
        }
    }
    .page-title {
        margin-top: 60px;
        margin-bottom: 20px;
        b {
            margin-right: 15px;
            font-Size: 22px;
        }
    }
    .total-count {
        font-size: 20px;
        font-weight: 500;
        color: rgb(51, 51, 51);
    }
    .category-list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 14px;
        margin-bottom: 40px;
        .category-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;

            border-radius: 10px;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            box-sizing: border-box;
            label {
                padding: 7px 16px;
                border-radius: 6px;
                cursor: pointer;
                &[aria-label="LVT"] {
                    padding: 7px 17px;
                }
                &[aria-label="LVS(롤시트)"] {
                    padding: 7px 18px;
                }
                &[aria-label="PVC"] {
                    padding: 7px 17px;
                }
            }
            input[type=checkbox] {
                display: none;
                & + label {
                    background: rgb(107, 107, 107);
                }
                &:disabled + label {
                    cursor: not-allowed;
                    background: rgba(107, 107, 107, .3);
                }
                &:checked + label {
                    background: rgb(0,0,0);
                    &::before {
                        margin-right: 8px;
                        content: '';
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                        background: url('../assets/img/icn_checked_green.svg') no-repeat center;
                    }
                }
            }
        }
    }
    .tiles {
        margin-bottom: 116px;
    }
    .list-wrap {
        width: 100%;
        column-gap: 25px;
        .no-result {
            margin-top: 150px;
            font-size: 18px;
            font-weight: 500;
            line-height: 21.48px;
            width: 100%;
            text-align: center;
            color: rgb(153, 153, 153, 1);
            .btn-request {
                margin-top: 20px;
                margin-bottom: 200px;
                width: 316px;
                height: 50px;
                border-radius: 12px !important;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                background: rgb(51, 51, 51);
                border: 0 none !important;
                justify-self: center;
            }
        }
        .tile-item {
            max-width: 268px;
            flex: unset;
            hr {
                --cui-border-width: 2px;
                color: rgb(51, 51, 51);
            }
            .product-name {
                margin-top: 26px;
            }
            .spec {
                font-weight: 400 !important;
            }
            .normal-price {
                font-weight: 500 !important;
                color: rgb(153, 153, 153);
            }
            .price {
                margin-right: 2px;
            }
        }
    }
}

#guideModal {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1029;
    .modal-dialog {
        padding: 14px 30px 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 788px;
        max-width: 100%;
        height: 512px;
        background: #ffffff;
        border-radius: 16px;
        box-shadow: 0 5.95px 17.84px 0 rgba(0, 0, 0, 0.2);
        pointer-events: all;
    }
    h2 {
        margin-bottom: 11px;
    }
    .form-check {
        margin-bottom: 0;
        .form-check-input {
            width: 14px;
            height: 14px;
            border-radius: 0;
            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgc3Ryb2tlPSIjMzMzMzMzIi8+Cjwvc3ZnPg==") no-repeat center center;
            &:checked {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgc3Ryb2tlPSIjMzMzMzMzIi8+CjxwYXRoIGQ9Ik0zLjUgNi40OTU3Mkw2LjY5Mjk4IDkuNzAyNjhMMTAuNSA0LjA0ODgzIiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
            }
        }
    }
    .form-check-label {
        font-size: 13px;
        font-weight: 500;
        color: rgb(51, 51, 51);
        vertical-align: middle;
    }
}

#loginModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10005;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }
    .modal-content {
        padding: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 418px;
        height: 508px;
        max-height: 100vh;
        background: #fff;
        border-radius: 12px;
        box-sizing: border-box;
        h2 {
            margin: 0 0 12px;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: -0.03em;
            text-align: center;
        }
        p {
            margin: 0 0 30px;
            font-size: 14px;
            font-weight: 500;
            color: rgb(141, 141, 141);
            line-height: 19px;
            letter-spacing: -0.03em;
            text-align: center;
            a {
                color: inherit;
            }
        }
    }
    .sns-list {
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .sns-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            height: 42px;
            font-size: 15px;
            font-weight: 600;
            border-radius: 6.13px;
            text-align: center;
            &::before {
                margin-right: 6px;
                content: '';
                width: 23px;
                height: 23px;
                display: inline-block;
            }
            &.naver-login {
                background: rgb(0, 207, 88);
                color: rgb(255, 255, 255);
                &::before {
                    background: url('../assets/img/logo_naver.svg') no-repeat center / 23px 23px;
                }
            }
            &.kakao-login {
                background: rgb(255, 233, 20);
                color: rgb(0, 0, 0);
                &::before {
                    background: url('../assets/img/logo_kakao.svg') no-repeat center / 23px 23px;
                }
            }
            &.email-login {
                border: 2px solid rgb(0, 0, 0);
                background: rgb(255, 255, 255);
                color: rgb(0, 0, 0);
                &::before {
                    background: url('../assets/img/logo_email.svg') no-repeat center / 23px 23px;
                }
            }
        }
    }
    .account-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        font-size: 14px;
        font-weight: 700;
        color: rgb(51, 51, 51, 1);
        a:not(:first-child)::before {
            margin-right: 8px;
            content: '|';
            display: inline-block;
            font-size: 14.72px;
            font-weight: 700;
            color: rgb(51, 51, 51);
        }
    }
    .btn-close {
        margin-top: 30px;
        width: 100%;
        height: 42px;
        flex-shrink: 0;
        border-radius: 6.13px;
        background: rgb(51, 51, 51);
        box-sizing: border-box;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        --cui-btn-close-opacity: 1;
    }
}

.delete-image-confirm {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    .modal-content {
        padding: 40px 20px 20px;
        width: 316px;
        height: 229px;
        border-radius: 20px;
        background: #fff;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10000;
        text-align: center;
        .icon {
            margin: 0 auto 24px;
            display: block;
            width: 36px;
            height: 36px;
        }
        p {
            margin: 0 0 40px;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: rgb(0, 0, 0);
        }
    }
    .modal-footer {
        column-gap: 10px;
        padding-bottom: 0;
        .btn {
            height: 45px;
            font-size: 16px;
            font-weight: 700;
        }
        .btn-cancel {
            border: 2px solid rgb(51, 51, 51);
            background-color: rgb(255, 255, 255) !important;
            color: rgb(51, 51, 51);
        }
        .btn-confirm {
            border-color: rgb(51, 51, 51);
            background-color: rgb(51, 51, 51);
            color: #fff;
        }
    }
}

.Toastify__toast-container {
    --toastify-color-default: rgba(51, 51, 51, .9);
    --toastify-color-success: rgba(32, 166, 76, .9);
    --toastify-toast-min-height: 50px;
    --toastify-toast-width: 450px;
    --toastify-toast-height: 50px;
    --toastify-toast-bottom: 50%;
    --toastify-font-family: 'Pretendard', sans-serif;
    padding: 0 !important;
    .Toastify__toast--default {
        background: var(--toastify-color-default) !important;
        color: #fff !important;
    }
    .Toastify__toast--success {
        background: var(--toastify-color-success) !important;
        color: #fff !important;
    }

    .Toastify__toast {
        height: 50px;
        padding: 0;
        box-shadow: none;
    }
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    min-width: 450px;
    color: #fff;
    .Toastify__toast-icon {
        display: none;
    }
    .Toastify__toast-body {
        min-width: 450px;
        white-space: nowrap;
        padding: 14px 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.48px;
        text-align: center;
    }
}

.wishlist {
    display: block;
    .table-wrap {
        margin-top: 29px;
    }
}

.material-finder-image {
    margin-bottom: 30px;
    padding-top: 40px;
    padding-left: 87px;
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    height: 460px;
    background: rgb(238, 238, 238);
    border-bottom: 2px solid rgba(216, 219, 224, 1);

    &.is-uploaded {
        height: 560px;
    }

    .drop-zone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin: 0 auto;
        padding: 80px 200px 80px 180px;
        width: 1100px;
        max-width: 100%;
        height: 300px;
        border: 3px dashed rgb(32, 166, 76);
        background: rgba(255, 255, 255, 0.5);
        &:not(.is-uploading) {
            flex-direction: column;
            background: rgba(255, 255, 255, 0.5) url('../assets/img/img_finder.svg') no-repeat 180px center / 140px 140px;
        }
        border-radius: 20px;
        box-sizing: border-box;
        font-size: 22px;
        font-weight: 500;
        color: rgba(153, 153, 153, 1);
        text-align: center;
        user-select: none;
        /*-webkit-user-drag: none;*/
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        position: relative;
        label {
            cursor: pointer;
        }
        &.dragging::after {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            background: rgba(26, 69, 40, 0.3);
            border-radius: 12px;
        }
        .uploader-error {
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
            padding: 12px;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            background-color: rgba(255, 0, 0, 0.06);
            border-radius: 12px 12px 0 0;
            color: rgb(74, 49, 49);
            b {
                &::before {
                    margin-right: 8px;
                    content: '';
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background: url('../assets/img/icon_alert.svg') no-repeat center / cover;
                    vertical-align: middle;
                }
                font-weight: 600;
                color: rgb(255, 89, 89);
            }
        }
        input[type=file] {
            display: none;
        }
        button {
            background: transparent;
            border: 0 none;
            font-size: 22px;
            font-weight: 700;
            text-decoration: underline;
            color: rgba(32, 166, 76, 1);
        }
        em {
            margin-top: 24px;
            display: block;
            font-size: 18px;
            font-weight: 400;
            font-style: normal;
            line-height: 21.48px;
            color: rgb(51, 51, 51);
        }
    }

    .img-area {
        margin: 0 auto;
        width: 1100px;
        max-width: 100%;
        height: 400px;
        position: relative;
        border-radius: 20px;
        flex-shrink: 0;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .1);
            border-radius: 20px;
        }
        >img,
        >canvas{
            width: 100%;
            max-width: 100%;
            height: 100%;
            border-radius: 20px;
            border: 3px solid rgb(255, 255, 255);
        }
        .btn-delete-image {
            position: absolute;
            top: -12px;
            right: -12px;
            width: 44px;
            height: 44px;
            background: rgb(51, 51, 51) url('../assets/img/icon_x_44.svg') no-repeat center / 20px 20px;
            border: 0 none;
            border-radius: 50%;
            &>span {
                font-size: 0;
                letter-spacing: -9999px;
                text-indent: -9999px;
            }
        }

        .cropper-modal {
            border-radius: 20px;
        }

        .cropper-view-box {
            outline: 0 none;
        }

        .cropper-line.line-n,
        .cropper-line.line-s,
        .cropper-line.line-w,
        .cropper-line.line-e,
        .cropper-point.point-n,
        .cropper-point.point-s,
        .cropper-point.point-w,
        .cropper-point.point-e {
            opacity: 0;
        }

        .cropper-point {
            box-sizing: border-box;
            background: transparent;
            border-radius: 2px;
        }

        .cropper-point.point-nw {
            left: -2px;
            top: -2px;
            width: 12px;
            height: 12px;
            border-top: 2px solid #FFFFFF;
            border-left: 2px solid #FFFFFF;
            cursor: url('../assets/img/icn_size_1.svg'), auto;
            opacity: 1;
        }
        .cropper-point.point-ne {
            right: -2px;
            top: -2px;
            width: 12px;
            height: 12px;
            border-top: 2px solid #FFFFFF;
            border-right: 2px solid #FFFFFF;
            cursor: url('../assets/img/icn_size_2.svg'), auto;
            opacity: 1;
        }
        .cropper-point.point-sw {
            left: -2px;
            bottom: -2px;
            width: 12px;
            height: 12px;
            border-bottom: 2px solid #FFFFFF;
            border-left: 2px solid #FFFFFF;
            cursor: url('../assets/img/icn_size_2.svg'), auto;
            opacity: 1;
        }
        .cropper-point.point-se {
            right: -2px;
            bottom: -2px;
            width: 12px;
            height: 12px;
            border-bottom: 2px solid #FFFFFF;
            border-right: 2px solid #FFFFFF;
            cursor: url('../assets/img/icn_size_1.svg'), auto;
            opacity: 1;
        }

        .cropper-move {
            cursor: url('../assets/img/icn_center.svg'), auto;
        }
    }

    .btn-area {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .btn-set-range {
        margin-bottom: 40px;
        width: 300px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid rgb(51, 51, 51);
        border-radius: 10px;
        background: #fff;
        font-size: 18px;
        font-weight: 700;
        line-height: 26.06px;
        &::before {
            width: 19px;
            height: 19px;
            content: '';
            display: inline-block;
        }
    }
    .btn-search {
        width: 316px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 16px;
        box-shadow: 0 4px 4px 0 rgba(51, 51, 51, 0.15);
        border: 0 none;
        border-radius: 10px;
        --cui-btn-font-size: 18px;
        font-weight: 700;
        line-height: 50px;
        color: #fff;
        &[disabled] {
            cursor: not-allowed;
        }
        &::before {
            margin-right: 8px;
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            content: '';
            display: inline-block;
            background: url('../assets/img/icn_material_search.svg') no-repeat center / 18px 18px;
        }
        b {
            display: block;
            font-size: 18px;
            font-weight: 700;
            font-family: 'Noto Sans KR', sans-serif;
            line-height: 26.06px;
        }
    }


    .network-error {
        font-size: 26px;
        font-weight: 600;
        line-height: 31.03px;
        text-align: center;
        color: rgba(51, 51, 51, 1);
    }
    .material-finder-image {
        height: 560px;
        padding-left: 24px;
        padding-right: 24px;
        &.is-network-error {
            height: 460px;
            justify-content: center;
        }
        .drop-zone {
            max-width: 100%;
            height: 400px;
            font-size: 16px;
            justify-content: flex-start;
            align-items: center;
            &:not(.is-uploading) {
                padding: 213px 20px;
                background-size: 80px;
                background-position: center 103px;
            }
            button {
                font-size: 16px;
            }
            em {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .network-error {
            margin-bottom: 30px;
            height: 300px;
            font-size: 20px;
            line-height: 36px;
            .btn {
                width: 100%;
            }
        }
        .list-wrap {
            .no-result {
                margin-top: 80px;
            }
        }
        .btn-search {
            width: 100%;
            --cui-btn-font-size: 14px;
        }
    }
    .material-finder-list {
        padding-left: 24px;
        padding-right: 28px;
    }
    .Toastify__toast-container {
        --toastify-toast-width: 252px;
    }
    #loginModal {
        .modal-content {
            padding: 24px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 345px;
            height: 453px;
            border-radius: 10px;
            h2 {
                font-size: 17px;
                line-height: 24px;
            }
            p {
                font-size: 13px;
            }
            .sns-list {
                margin-bottom: 24px;
                .sns-btn {
                    font-size: 16px;
                    font-weight: 600;
                    &.naver-login,
                    &.kakao-login,
                    &.email-login {
                        &::before {
                            background-size: 20px 20px;
                        }
                    }
                    &.email-login {
                        border-width: 1px;
                    }
                }
            }
        }
    }
    html .bottom-sticky {
        padding-top: 10px;
        border-top: 0 none;
        &.static {
            .container-xl {
                column-gap: 8px;

                .btn {
                    --cui-border-width: 1px;
                    height: 40px;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 6px;
                }
            }
        }
    }
}

.sample-request,
.buy-request {
    >form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .table {
            thead th {
                height: 61px;
                border-top: 3px solid rgb(51, 51, 51);
            }
            tbody {

            }
        }
    }
    .container-xl {
        display: flex;
        .request-complete {
            align-self: center;
        }
    }
    .height-auto+.bottom-sticky {
        margin-top: 0;
    }
    textarea.form-control {
        height: 192px;
        resize: none;
    }
    .row {
        --cui-gutter-x: 0;
        --cui-gutter-y: 30px;
        > * {
            --cui-gutter-y: 40px;
        }
        .col {
            &.has-label {
                max-width: 193px;
            }
            &.has-control {
                max-width: 663px;
            }
        }
        .form-label {
            margin-top: 22px;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
            &.required {
                color: rgb(255, 89, 89);
            }
        }
        .form-text.caution {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 500;
            color: rgb(255, 89, 89);

        }
        .form-check {
            display: flex;
            align-items: center;
        }
        .form-check-input {
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTEiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPg==') !important;
        }
        .form-check-input:checked[type=radio], .radio .form-check-input:checked[type=checkbox] {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAzMSAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTUuNSIgY3k9IjE1LjUiIHI9IjE0LjUiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxjaXJjbGUgY3g9IjE1LjUiIGN5PSIxNS41IiByPSI3LjUiIGZpbGw9IiMyMEE2NEMiLz4KPC9zdmc+Cg==') !important;
        }
        .form-check-label {
            margin-left: 10px;
            font-size: 18px;
            font-weight: 600;
            color: rgb(51, 51, 51);
        }
        .react-datepicker__input-container input {
            background: url('../assets/img/icn_calendar.svg') no-repeat calc(100% - 19px) center / 22px 22px;
            color: rgb(51, 51, 51) !important;
            font-size: 16px;
            font-weight: 500 !important;
            &::placeholder {
                color: rgb(153, 153, 153) !important;
                font-size: 16px !important;
                font-weight: 500 !important;
            }
        }
        .react-datepicker-wrapper {
            display: block;
            width: 100%;
            input {
                width: 100%;
                height: 48px;
                padding: 14.5px 18px !important;
                border: 1px solid rgb(51, 51, 51);
                border-radius: 6px;
            }
            .react-datepicker__today-button {
            }
        }
        .react-datepicker__tab-loop {
            .react-datepicker-popper {
                border: 1px solid rgb(51, 51, 51);
                border-radius: 6px;
                z-index: 99999;
                .react-datepicker__today-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 74px;
                    height: 34px;
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    border-radius: 7.37px;
                    border: 2.21px solid rgb(51, 51, 51);
                    background: rgb(255, 255, 255);
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            .react-datepicker {
                border: 0 none;
                width: 663px;
                border-radius: 6px;
            }
            .react-datepicker__header {
                padding: 0;
                background-color: transparent;
                border-bottom: 0 none;
                font-size: 22px;
                font-weight: 700;
                button {
                    padding: 0;
                    background: transparent;
                    border: 0 none;
                }
                .react-datepicker__day-names {
                    display: flex;
                    margin-bottom: 22px;
                    .react-datepicker__day-name {
                        margin: 0;
                        width: 100%;
                        flex-shrink: 1;
                        &[aria-label="토요일"],
                        &[aria-label="일요일"] {
                            opacity: .6;
                        }
                    }
                    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }

            }
            .react-datepicker__triangle {
                display: none;
            }
            .react-datepicker__month-container {
                width: 100%;
                padding: 30px;
                position: relative;
                .react-datepicker__month {
                    margin: 0;
                    display: flex;
                    flex-wrap: wrap;
                    .react-datepicker__week {
                        display: flex;
                        width: 100%;
                        white-space: normal;
                        &:not(:last-child) {
                            margin-bottom: 28px;
                        }
                        .react-datepicker__day:not([aria-disabled=true]):hover, .react-datepicker__month-text:not([aria-disabled=true]):hover, .react-datepicker__quarter-text:not([aria-disabled=true]):hover, .react-datepicker__year-text:not([aria-disabled=true]):hover {
                            background: transparent;
                        }
                        .react-datepicker__day {
                            margin: 0;
                            width: 100%;
                            height: 21px;
                            color: rgb(51, 51, 51);
                            font-size: 18px;
                            font-weight: 400;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &.react-datepicker__day--keyboard-selected {
                                color: #ffffff;
                                font-weight: 400;
                                background: transparent;
                                z-index: 0;
                                &::after {
                                    content: '';
                                    display: block;
                                    width: 38px;
                                    height: 38px;
                                    background: rgba(51, 51, 51, 1);
                                    border-radius: 18.42px;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: -1;
                                }
                            }
                        }
                    }
                }
            }
            .react-datepicker__day--disabled {
                color: rgba(51, 51, 51, 1);
                opacity: .2;
            }
        }
        .table {
            margin-top: 30px;
            margin-bottom: 0 !important;
            border-bottom: 2px solid rgb(51, 51, 51);
            thead {
                display: table;
                width: 100%;
                table-layout: fixed;
                th {
                    padding: 20px 0 !important;
                    color: rgb(119, 119, 119) !important;
                    border-bottom-color: rgb(221, 221, 221) !important;
                    font-size: 18px !important;
                    font-weight: 600;
                }
            }
            tbody {
                display: block;
                table-layout: fixed;
                width: calc(100% + 10px);
                max-height: 339px;
                overflow-x: hidden;
                overflow-y: scroll;
                scroll-behavior: smooth;
                tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }

                &::-webkit-scrollbar {
                    width: 10px;
                    height: 300px;
                    background: transparent;
                    border-radius: 50px;
                    &-thumb {
                        background: rgba(217, 217, 217, 1);
                        border-radius: 50px;
                    }
                    &-track {
                        background: transparent;
                    }
                }
                td {
                    padding: 16px 0 !important;
                }
                td {
                    height: 100px;
                    color: rgb(51, 51, 51);
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 21.48px;
                    border-bottom-color: rgb(221, 221, 221);
                }
            }
            th,
            td {
                &.check {
                    display: none;
                }
                &.no {
                    width: 213.33px;
                }
                &.thumb {
                    width: 140px;
                }
                &.product-name {
                    width: 213.33px;
                }
                &.cat-name {
                    width: 213.33px;
                }
                &.color {
                    width: 213.33px;
                }
                &.stock {
                    width: 213.33px;
                }
                &.action {
                    width: 213.33px;
                    .btn {
                        padding: 0;
                        background: transparent;
                        border: 0 none;
                    }
                }
            }
        }
        .btn {
            height: 100%;
            --cui-border-radius: 6px;
        }
    }
    .form-check+.form-check {
        margin-left: 20px;
    }

    .address-search {
        --cui-gutter-x: 20px;
        margin-left: 0;
        > * {
            padding-left: 0;
            margin-left: 0;
            box-sizing: content-box;
        }
    }

    .form-control,
    .form-select {
        height: 48px;
        padding: 14.5px 18px !important;
        border: 1px solid rgba(51, 51, 51, 1);
        border-radius: 6px;
        color: rgb(51, 51, 51) !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        &::placeholder {
            color: rgb(153, 153, 153) !important;
            font-size: 16px !important;
            font-weight: 500 !important;
        }
    }
    .input-group {
        &.stock {
            margin-left: auto;
            margin-right: auto;
            border-color: rgb(217, 217, 217);
            .btn {
                padding: 0 !important;
                border-radius: 0 !important;
                background: #ffffff !important;
                color: rgba(193, 193, 193, 1);
                border-color: rgb(217, 217, 217);
            }
            .form-control {
                width: 35px;
                padding: 0 !important;
                border: 1px solid rgb(217, 217, 217) !important;
                text-align: center;
                color: rgb(51, 51, 51) !important;
                font-size: 18px !important;
            }
        }
    }
    .form-wrapper {
        margin-top: 60px;

        h4 {
            margin-bottom: 0;
        }
        hr {
            margin-top: 30px;
            margin-bottom: 40px;
            color: rgb(221, 221, 221);
        }
    }
    .recipient-info {
        margin-left: 40px;
        h4 { margin-bottom: 20px; }
    }
    .btn-group {
        width: 100%;
        .dropdown-toggle {
            width: 100%;
            --cui-btn-color: rgb(51, 51, 51) !important;
            --cui-btn-active-color: rgb(51, 51, 51) !important;
            --cui-btn-hover-color: rgb(51, 51, 51) !important;
            --cui-btn-bg: rgb(255, 255, 255);
            --cui-btn-hover-bg: rgb(255, 255, 255);
            --cui-btn-active-bg: rgb(255, 255, 255);
            &.dropdown-placeholder {
                --cui-btn-color: rgb(153, 153, 153) !important;
                --cui-btn-active-color: rgb(153, 153, 153) !important;
                --cui-btn-hover-color: rgb(153, 153, 153) !important;
            }

            padding: 0 18px;
            height: 48px !important;
            text-align: left;
            font-size: 16px !important;
            font-weight: 500 !important;
            border: 1px solid rgb(51, 51, 51);
            line-height: normal;
            &::after {
                position: absolute;
                top: 50%;
                right: 18px;
                border: 0 none;
                width: 24px;
                height: 24px;
                background: url('../assets/img/icn_down.svg') no-repeat center / 24px 24px;
                transform: translateY(-50%);
            }
        }
        .btn-group.show .dropdown-toggle {
            &::after {
                background-image: url('../assets/img/icn_up.svg');
            }
        }
    }
    .dropdown-menu {
        --cui-dropdown-item-padding-y: 0;
        width: 100%;
        top: 10px;
        padding: 6px 0;
        border-radius: 6px;
        border: 1px solid rgba(51, 51, 51, 1);
        li:not(:last-child) {
            margin-bottom: 8px;
        }
        .dropdown-item {
            padding: 8px 0 8px 16px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.71px;
            >b {
                display: inline-block;
                width: 100px;
            }
            >span {
                font-weight: 400;
                font-size: 14px;
                color: rgb(255, 89, 89);
            }
        }
    }
    .request-complete {
        width: 100%;
        font-size: 18px;
        .complete-icon {
            width: 60px;
            height: 60px;
        }
        .complete-msg {
            b {
                margin-bottom: 6px;
            }
            p {
                line-height: 21.48px;
            }
        }
        .btn-area {
            column-gap: 8px;
            .btn {
                --cui-border-radius: 6px;
                --cui-btn-font-size: 14px;
                height: 40px;
            }
        }
    }
}

.request-complete {
    width: 750px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 26px;
    .complete-icon {
        margin-bottom: 40px;
    }
    .complete-msg {
        font-weight: 400;
        b {
            font-weight: 600;
            display: block;
        }
        p {
            margin: 10px 0 0;
            line-height: 31.03px;
        }
    }
    .btn-area {
        display: flex;
        margin-top: 120px;
        column-gap: 20px;
        .btn {
            --cui-border-radius: 20px;
            --cui-btn-font-size: 22px;
            width: 100%;
            height: 70px;
            flex-shrink: 1;
            font-size: var(--cui-btn-font-size);
        }
    }
}

.request-list {
    display: flex;
    flex-direction: column;

    .table {
        td {
            font-weight: 500;
        }
        .order-no {
            width: 140px;
        }
    }

    .btn {
        --cui-btn-font-size: 18px;
        --cui-border-radius: 10px;
        width: 250px;
        height: 50px;
    }
}

.bottom-sticky {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    margin-top: 120px;
    padding: 30px 0;
    width: 100%;
    height: 160px;
    border-top: 1px solid rgba(221, 221, 221, 1);
    background: #ffffff;
    box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, 0.2);
    z-index: 10000;
    &.static {
        padding: 60px 0;
        position: static;
        height: auto;
        margin-top: 40px;
        box-shadow: none;
        .container-xl {
            column-gap: 12px;
            .btn {
                font-size: 18px;
                font-weight: 500;
                height: 50px;
            }
        }
    }
    .container-xl {
        margin: 0 auto;
        padding: 0;
        display: flex;
        align-items: center;
        column-gap: 20px;
        .btn {
            --cui-border-radius: 12px;
            width: 100%;
            height: 80px;
            flex-shrink: 1;
            font-size: 26px;
            font-weight: 700;
            &.submit {
                pointer-events: auto !important;
            }
        }
    }

    &.sticky {
        bottom: 0;
    }
}

.btn-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    --cui-btn-bg: rgb(51, 51, 51);
    --cui-btn-hover-bg: rgb(51, 51, 51);
    --cui-btn-active-bg: rgb(51, 51, 51);
    --cui-btn-color: rgb(255, 255, 255);
    --cui-btn-hover-color: rgb(255, 255, 255);
    --cui-btn-active-color: rgb(255, 255, 255);
    --cui-btn-hover-border-color: rgb(255, 255, 255);
}

.btn-outline-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    --cui-btn-bg: rgb(255, 255, 255);
    --cui-btn-hover-bg: rgb(255, 255, 255);
    --cui-btn-active-bg: rgba(0, 0, 0, .1);
    --cui-btn-border: 2px solid rgb(51, 51, 51);
    --cui-border-width: 2px;
    --cui-btn-border-color: rgb(51, 51, 51);
    --cui-btn-hover-border-color: rgb(51, 51, 51);
    --cui-btn-active-border-color: rgb(51, 51, 51);
    --cui-btn-color: rgb(51, 51, 51);
    --cui-btn-hover-color: rgb(51, 51, 51);
    --cui-btn-active-color: rgb(51, 51, 51);
    border: var(--cui-btn-border) !important;
}

.btn-success {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cui-btn-bg) !important;
    --cui-btn-bg: rgb(32, 166, 76);
    --cui-btn-color: rgb(255, 255, 255);
    --cui-border-radius: 10px;
    --cui-border-radius-sm: 10px;
    --cui-border-radius-lg: 10px;
    --cui-border-radius-xl: 10px;
    --cui-btn-font-size: 20px;
    --cui-btn-active-bg: rgb(27, 143, 65);
    --cui-btn-active-color: rgb(255, 255, 255);
    --cui-btn-disabled-opacity: .3;
    font-size: var(--cui-btn-font-size);

    &:active,
    &.active {
        background-color: var(--cui-btn-active-bg) !important;
    }

    &:disabled,
    &.disabled {
        color: var(--cui-btn-color) !important;
    }
}

.btn-prev,
.btn-next {
    width: 30px;
    height: 30px;
    >span {
        font-size: 0;
        letter-spacing: -9999px;
        word-spacing: -999px;
        opacity: 0;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-drag: none;
    }
    &[disabled] {
        display: none;
    }
}

.btn-prev {
    margin-right: 12px;
    background: transparent url('../assets/img/icn_before.svg') no-repeat center / 30px 30px !important;
}
.btn-next {
    margin-left: 12px;
    background: transparent url('../assets/img/icn_next.svg') no-repeat center / 30px 30px !important;
}

/**
 공통 테이블 스타일
 */
.table-wrap {
    .btn-area {
        margin-top: 13px;
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;
        .btn-delete-all {
            width: 95px;
            height: 41px;
        }
    }
    .table {
        thead {
            display: table;
            width: 100%;
            table-layout: fixed;
            th {
                .form-check-input {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxyZWN0IHg9IjEiIHk9IjEuMDAwMTIiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjEuNSIgZmlsbD0id2hpdGUiLz4KICAgIDxyZWN0IHg9IjEiIHk9IjEuMDAwMTIiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjEuNSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+") !important;
                    &:checked {
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMS4wMDAxMiIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMS41IiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSIxIiB5PSIxLjAwMDEyIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHJ4PSIxLjUiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik01LjQzNDU3IDkuNTA0NEw5LjU5OTMzIDEzLjY4NzRMMTQuNTY1IDYuMzEyODEiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+") !important;
                    }
                }
            }
        }
        tbody {
            tr {
                display: table;
                width: calc(100% + 10px);
                table-layout: fixed;
                overflow-x: hidden;
                overflow-y: scroll;
                padding-right: 10px;
                &:not(:last-child) {
                    td {
                        border-bottom: 1px solid rgb(221, 221, 221);
                    }
                }
            }
            &::-webkit-scrollbar {
                width: 10px;
                height: 300px;
                background: transparent;
                border-radius: 50px;
                &-thumb {
                    background: rgba(217, 217, 217, 1);
                    border-radius: 50px;
                }
                &-track {
                    background: transparent;
                }
            }
        }
    }
    .form-check-input {
        width: 20px !important;
        height: 20px !important;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC43NSIgeT0iMC43NTAxODMiIHdpZHRoPSIxOC41IiBoZWlnaHQ9IjE4LjUiIHJ4PSIxLjc1IiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSIwLjc1IiB5PSIwLjc1MDE4MyIgd2lkdGg9IjE4LjUiIGhlaWdodD0iMTguNSIgcng9IjEuNzUiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPHBhdGggZD0iTTUuNDM0ODEgOS41MDQ0OUw5LjU5OTU3IDEzLjY4NzVMMTQuNTY1MiA2LjMxMjkiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS43MzkxMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") !important;
        &:checked {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC43NSIgeT0iMC43NTAxODMiIHdpZHRoPSIxOC41IiBoZWlnaHQ9IjE4LjUiIHJ4PSIxLjc1IiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSIwLjc1IiB5PSIwLjc1MDE4MyIgd2lkdGg9IjE4LjUiIGhlaWdodD0iMTguNSIgcng9IjEuNzUiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPHBhdGggZD0iTTUuNDM0ODEgOS41MDQ0OUw5LjU5OTU3IDEzLjY4NzVMMTQuNTY1MiA2LjMxMjkiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4=") !important;
        }
    }
}

.cart {
    &.mypage .page-title p {
        margin-bottom: 19px;
    }
    .table-wrap {
        .btn-area {
            margin-top: 0;
        }
    }

    .btn-sample-request,
    .btn-buy-request {
        --cui-border-radius: 10px;
        --cui-btn-font-size: 18px;
        width: 254px;
        height: 50px;
    }

    .btn-sample-request {
        margin-right: 12px;
    }
}

@media (max-width: 1160px) {
    :target {
        scroll-margin-top: 80px !important;
        &#showroom {
            scroll-margin-top: 116px !important;
        }
    }
    .header .nav-item.material-finder {
        margin-right: 0;
    }
    .modal .width11 { width: 7rem !important; }
    .buss_img_modal {
        --cui-modal-border-radius: 9.5px 9.5px 0 0;
        --cui-modal-width: 100%;
        --cui-modal-header-padding: 20px 0;
        .modal-dialog {
            margin: 0;
            max-width: var(--cui-modal-width);
            &.modal-dialog-centered {
                --cui-modal-margin: 0;
                min-height: 100%;
                align-items: flex-end;
            }
            .modal-content {
                border-radius: var(--cui-modal-border-radius);
                .modal-body {
                    --cui-modal-padding: 0 26px;
                    padding: var(--cui-modal-padding);
                }
                .img-wrap {
                    height: 300px;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 7px;
                        height: 7px;
                        background: transparent;
                    }
                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #BCBCBC;
                        border-radius: 13px;
                    }
                }
                .modal-footer {
                    padding: 24px 24px 30px;
                    column-gap: 8px;
                    .btn {
                        margin: 0;
                    }
                }
            }
        }
    }
    .delete-image-confirm {
        .modal-footer {
            flex-wrap: nowrap;
            padding-top: 0;
        }
    }
    .buy-question-modal {
        .width10 { width: 8rem !important; }
        .form-control {height: 36px;}
    }
    .header {
        padding: 14px 24px; height: 68px; border-width: 2px;
        .mobile-header {
            display: flex; align-content: center; justify-content: space-between; flex: 1;
            margin: 0;
            .btn-group { margin-top: 6px;
                .btn {padding: 0; height: auto;}
            }
            .rigth-wrap { position: relative; width: 6rem; text-align: right;
                button {
                    --cui-btn-padding-x: 0;
                    --cui-btn-padding-y: 0;
                    --cui-btn-border-radius: 32.5px;
                    --cui-btn-font-size: 15px;
                    --cui-btn-font-weight: 700;
                    width: 89px;
                    height: 35px
                }
                button:hover {background-color: #9A9A9A !important; border-color: #9A9A9A !important}
                .popover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: 51px;
                    width: 216px;
                    height: 40px;
                    font-size: 12.8px;
                    font-weight: 700;
                    font-family: Pretendard, sans-serif;
                    line-height: 15.28px;
                    text-align: center;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
                    color: #fff;
                    border: 0 none;
                    border-radius: 400px;
                    background: rgb(0, 199, 60);
                    /* Arrow (12x8) */
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -8px;
                        right: 23px;
                        width: 12px;
                        height: 8px;
                        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjIwMDc4IDBMMTIuODAwOCA4LjhIMC44MDA3ODFMNy4yMDA3OCAwWiIgZmlsbD0iIzAwQzczQyIvPgo8L3N2Zz4=");
                    }
                    .btn-close {
                        margin: 0 0 0 4px;
                        padding: 0;
                        width: 12px;
                        height: 12px;
                        background: url('../assets/img/icon_x_white.svg') no-repeat center / 12px 12px;
                        opacity: 1;
                    }
                }
            }
            .dropdown-menu {
                margin: 16px 0 0px -25px !important; padding: 0; width: 74vw;
                li { border-bottom: 1px solid #000; padding: 13px 24px;
                    .dropdown-item { font-size: 22px; font-weight: 700; padding: 0; color: black; }
                }
                li:last-child {border-bottom: none;}
                li:has(.dropdown-item.active:not([href*='#'])) {background-color: #E5E6E1; }
                &.auth {
                    li:last-child {background-color: #000 !important; text-align: center; color: white;}
                }
                &.none-auth {
                    li:nth-last-child(2) {background-color: #E5E6E1; text-align: center;}
                    li:last-child {background-color: #000 !important; text-align: center; color: white;}
                }
            }
            .dropdown-menu.show + .menu-mask {position: absolute; width: 100vw; height: 100vh; background: rgba(0,0,0,0.5); top: 49px; left: -24px; z-index: 9; display: block;}
        }
        .header-brand {font-size: 20px; padding: 0; margin-right: 0 !important}
    }
    .header-pop {
        background-color: transparent; text-align: right; width: 100vw; max-width: 100vw; border: none; margin-left: -1rem !important;
        .popover-arrow {left: 1rem !important;}
        .popover-body { width: auto; display: inline-block; background-color: #00C73C; border-radius: 10rem; }
    }
    .mobile-header {
        margin: 23px 24px;
        display: flex; align-items: center; justify-content: space-between; height: 12px;
    }
    .container-xl {
        .mobile-header {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .footer {padding: 20px 24px; font-size: 10px; text-align: left;
        .sns-wrap {position: absolute; right: 2rem;}
    }

    .login {
        background: #E5E6E1;
        input { border: none; ::placeholder {font-size: 15px; font-weight: 300; } }
        .btn-sns { .img-wrap {width: 30px; padding-right: 7px; padding-bottom: 0; } }
        .form-label { top: -2px; right: 2px; font-size: 12px;}
    }
    .register {
        .btn-sns {height: 46px;}
        .item {
            padding: 16px;
            img {width: 92px;}
        }
        .data-wrap {
            .data-label {width: 110px; font-size: 15px; padding-top: 5px;}
            .data { flex: 1;
                .btn {
                    height: 30px; font-size: 14px !important; padding: 8px 12px;
                }
                .no-data { font-size: 15px; height: 144px; }
                .width5 {width: 4rem !important; padding: 5px;}
            }
        }
        .form-control {height: 30px; }
        .width11 {width: 100% !important;}
        .cert-input {padding: 5px;}
    }
    .home {
        .fs-115 {font-size: 50px !important;}
        .fs-115.with {font-size: 30px !important;}
        .fs-56 {font-size: 30px;}
        .container-xl {
            --cui-gutter-x: 60px;
        }
        .banner {
            height: 532px;
            padding: 0 34px;
            background: url('../assets/img/main_banner_mobile.webp') no-repeat center / cover;
            .banner-text {
                top: 171px;
                width: 100%;
                letter-spacing: -0.6px;
                line-height: 30.67px;
            }
            .btn-area {
                margin-top: 30px;
                .btn {
                    width: 190px;
                    height: 42px;
                    --cui-btn-font-size: 16px;
                    --cui-btn-font-weight: 600;
                    letter-spacing: -1.4px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .story {
            height: 831px;
            .row {
                --cui-gutter-x: 16px;
            }
            .story-card {
                min-height: 128px;
            }
            img {
                width: 55px;
                height: 42px;
            }
            .bg-success {
                height: 156px;
                line-height: 24px;
            }
        }
        .showroom {
            height: 387px;
        }
        .portfolio {
            height: 403px;
            .swiper {
                height: 234px;
                .swiper-slide {
                    width: 100% !important;
                    height: 234px;
                    img {
                        height: 200px;
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                width: 30px !important;
                height: 200px !important;
            }
            .swiper-button-prev {
                &::before {
                    left: 20px;
                }
            }
            .swiper-button-next {
                &::before {
                    right: 20px;
                }
            }
        }

        .swiper-container { width: calc(100% + 24px); margin: 0 -12px; }
        section {padding: 30px 0;}
        .tile-slider-wrap {margin: 0 -12px;
            .swiper-slide {width: 70vw;
            }
            .tile-slider {width: 70vw; border: 1px solid #000; margin-right: 12px;
                .thumb {width: 100%; height: 250px; background-position: center; background-repeat: no-repeat; background-size: cover;}
                .bottom {padding: 12px;}
            }
            .swiper-scrollbar { height: 4px; background: transparent; margin-top: 25px; position: relative;}
            .swiper-scrollbar:before{content:"";width:100%;height:2px; display: inline-block;background:#aaa; position: absolute; top: 1px; left: 0;}
            .swiper-scrollbar-drag { background: black; height: 4px;}
        }
        .why-wrap {
            li {padding: 20px 24px; width: 100%; border-left: none; border-bottom: 1px solid;}
            li:nth-last-child(2), li:last-child {border-bottom: none;}
        }
        .mt--10 {top: 0}
        .progress-wrap{position:relative;padding:0 1rem 0 5rem;margin:2rem 0 0}
        .progress__item{position:relative;min-height:75px;counter-increment:list;padding-left:.5rem;padding-bottom:2rem}
        .progress-mark{position:absolute;left:-4.3rem;top:-10px;font-size:42px}
        .progress__title{padding:.4rem 0 .5rem;margin:0;font-size:24px;font-weight:500}
        .progress__info{font-size:15px;line-height:25px}
        .progress__item:before{content:"";position:absolute;left:-1.5rem;top:20px;height:100%;width:1px;border-left:1px solid #000}
        .progress__item:after{content:"";position:absolute;top:20px;left:-1.7rem;width:7px;height:7px;border-radius:50%;background:#000}
        .accordion {
            .accordion-button { font-size: 19px; padding: 16px 4px;}
            .accordion-body {padding: 16px; padding-top: 0;}
        }
        .map-wrap {
            display: block;
            .left-wrap {width: 100%; padding: 16px;border-right: none;}
            .right-wrap {padding: 1px; border-top: 1px solid; border-bottom: 1px solid;}
        }
    }
    .tiles {
        .search-wrap {padding: 0 24px;}
        .cnt-wrap {display: flex; align-items: center; justify-content: space-between;}
        .keyword-wrap { display: block;
            .form-control {margin: 10px 0;}
            .select-wrap { display: flex; }
            .dropdown { flex: 1;
                button{width:100% !important; font-size:13px !important; padding: 2px 4px !important; height: 30px;}
                button::after{ width:10px !important;height:6px !important; top:11px !important;}
            }
            .dropdown-menu { min-width: 100% !important; margin-top: -11px !important; transform: translate3d(-24px, -40px, 0px) !important;
                .dropdown-item { font-size: 13px !important; padding: 4px 10px !important; }
            }
            .dropdown-menu.show + .menu-mask {position: absolute; width: 100vw; height: calc(100vh - 80px); background: rgba(0,0,0,0.5); top: -51px; left: -24px; z-index: 9; display: block;}
            .filter-wrap {
                max-height: calc(100vh - 80px); overflow-y: scroll;
            }
        }
        .key-wrap {
            .keyword-item { font-size: 14px; }
        }
        .list-wrap {
            column-gap: 12px;
            row-gap: 25px;
            .tile-item {
                margin-bottom: 0; flex: 1 1 48%; max-width: 48%;

                .action {
                    top: 48%;
                    right: 4%;
                    column-gap: 8px;
                }
            }
        }
    }
    .material-finder-image {
        height: 560px;
        padding-left: 24px;
        padding-right: 24px;
        .drop-zone {
            height: 400px;
            >div {
                width: 100%;
            }
            &:not(.is-uploading) {
                background-size: 80px 80px;
                background-position: center 103px;
            }
            padding: 183px 20px 40px;
            button {
                font-size: 16px;
            }
            em {
                width: 100%;
                font-size: 14px;
                line-height: 20px;
            }
            .uploader-error {
                padding: 16px 20px;
                height: 77px;
                line-height: 14.32px;
                b::before {
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .img-area {
            border-radius: 12px !important;

            .btn-delete-image {
                background-size: 13px 13px !important;
                width: 30px !important;
                height: 30px !important;
                right: -9px !important;
                top: -9px !important;
            }
        }
        .btn-search {
            width: 100%;
        }
    }
    .material-finder-list .list-wrap {
        display: grid;
        column-gap: 15px;
        row-gap: 24px;
        grid-template-columns: repeat(2, 1fr);
        .tile-item {
            width: 100%;
            max-width: 100%;
            .product-name {
                margin-top: 10px;
            }
        }
    }
    #guideModal {
        padding: 0;
        top: auto;
        bottom: 0;
        &::after {
            display: none;
        }
        .modal-dialog {
            height: 653px;
            padding: 24px;
            left: 0;
            top: auto;
            bottom: 0;
            transform: none;
            z-index: 1029;
            position: absolute;
            background: #fff;
            border-radius: 16px 16px 0 0;
            .btn {
                margin-bottom: 36px;
            }
        }
    }
    .detail {
        position: relative;
        .container-xl {
            --cui-gutter-x: 60px;
        }
        .kakao-contact-box {
            bottom: 35px;
        }
        .swiper-wrap {
            .thumb {
                height: calc(100vw - 24px)
            }
            .swiper-scrollbar { height: 4px; background: transparent; margin-top: 25px; position: relative;}
            .swiper-scrollbar:before{content:"";width:100%;height:2px; display: inline-block;background:#aaa; position: absolute; top: 1px; left: 0;}
            .swiper-scrollbar-drag { background: black; height: 4px;}
        }
        .bottom-wrap {
            position: fixed; bottom: 0; left: 0; width: 100%; padding: 12px; display: flex; gap: 8px; z-index: 9;
            .btn-bookmark { border-radius: 4px; border: 2px solid #000; background: #FFF; display: flex; align-items: center; justify-content: center; width: 62px; height: 62px;
                img {width: 41px;}
            }
            .btn {height: 62px;}
        }
        .nav {
            .nav-item {flex: 1;}
        }
        // .accordion-body {
        //     .col-md-6:last-child .b-bottom {border-bottom: none !important; padding: 0 !important;}
        // }
        .square-calc {
            .list { padding: 8px 14px; }
            .width4 { width: 3rem !important; }
            hr {margin: 0 14px;}
        }
        .sm-ctrl { width: 51px; padding: 3px; }
        select.sm-ctrl { width: 60px; padding: 5px 10px 5px 5px !important; }
        .comment-wrap {
            .row {border-bottom: 2px solid rgba(0, 0, 0, 0.15); padding-bottom: 1.5rem;}
            .swiper-page{border-radius:30px;background:rgba(0,0,0,.5);width:54px;height:25px;display:flex;align-items:center;justify-content:center;color:#fff;font-size:11px; line-height: 11px;font-weight:400;position:absolute;bottom:10px;right:41%;z-index:1}
            .swiper-button-prev, .swiper-button-next {display: none;}
        }
        table.use-info { color: #000; border-bottom: 1px solid;
            th, td { padding: 6px !important; font-size: 15px; }
        }
        .flex-3 {flex: 1;}
    }
    .buy-question-modal {
        ul {
            li {padding: 8px; }
        }
    }

    .kakao-contact-box {
        .kakao-contact {
            right: 24px;
            img {
                width: 88px;
                height: 39px;
                border-radius: 22px;
            }
        }
    }

    .content-wrapper {margin-left: 0}
    // mypage
    .mypage {
        .container-xl {padding: 0;}
        .banner-wrap {
            padding: 32px;
            display: block;
            height: auto;
            .benifit-wrap {
                background: transparent;
                padding: 5px 0;
                text-align: left;
                .btn {
                    height: 27px;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 2px 5px;
                }
            }
            .benifit-wrap::before, .benifit-wrap::after { display: none;}
            .status-wrap {
                background-color: white; padding: 16px; font-size: 13px; display: block; border-radius: 4px; margin-top: 1rem;
            }
        }
        .menu-wrap {
            padding: 40px 0; font-size: 17px; border: none; width: 100%;
            hr {margin: 0; display: none;}
            ul {
                li {padding: 10px 25px; border-bottom: 1px solid; margin-bottom: 0;
                    a { display: flex; align-items: center; justify-content: space-between;
                        img {display: block;}
                    }
                }
                li:first-child {border-bottom: none;}
                li:last-child { margin-bottom: 23px; }
            }
        }
        .mobile-header {
            a {height: 12px}
        }

        &.cart,
        &.wishlist {
            flex-direction: column;
            .table {
                border-top: 2px solid #333;
                thead {
                    display: none !important;
                }
                .thumb {
                    width: 72px !important;
                    height: 72px !important;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                td {
                    &.check {
                        width: 30px;
                        .form-check-input {
                            margin-right: 8px;
                        }
                    }
                    &.no,
                    &.cat-name,
                    &.color,
                    &.stock,
                    &.action {
                        display: none;
                    }
                    &.thumb {
                        width: 72px;
                        height: 72px;
                        vertical-align: top;
                    }

                    .btn {
                        height: 34px;
                    }
                }
            }
            .btn {
                --cui-btn-border-radius: 6px;
                --cui-btn-font-size: 14px;
                --cui-btn-font-weight: 500;
                height: 40px;
            }
            .btn-cart {
                width: 100%;
            }
            .btn-sample-request,
            .btn-buy-request {
                width: 50%;
            }
            .btn-sample-request {
                margin-right: 8px;
            }
        }
        &.request-list {
            .table {
                thead {
                    display: none;
                }
                tbody {
                    tr {
                        display: flex;
                        flex-wrap: wrap;
                        &:not(:last-child) {
                            margin-bottom: 30px;
                        }
                    }
                    td {
                        width: 100%;
                        display: block;
                        flex-shrink: 0;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 21px;
                        &.order-no {
                            padding: 9px 12px !important;
                            background: #F3F3F3 !important;
                            border-top: 2px solid #333;
                        }
                        &.thumb {
                            img {
                                width: 72px;
                                height: 72px;
                            }
                        }
                        &.product-name,
                        &.cat-name,
                        &.color,
                        &.quantity,
                        &.price,
                        &.status {
                            display: none;
                        }
                        .date {
                            display: block;
                            font-weight: 700;
                        }
                    }
                }
            }
            .btn {
                --cui-btn-border-radius: 6px;
                --cui-btn-font-size: 14px;
                --cui-btn-font-weight: 500;
                width: 100%;
                height: 40px;
            }
        }
    }

    .buy-request,
    .sample-request {
        .form-wrapper {
            margin-top: 50px;
            h4 {
                margin-bottom: 30px;
            }
        }
        .row {
            .form-check-label {
                font-size: 16px !important;
            }
            .row {
                >* {
                    --cui-gutter-y: 0;
                }
            }
        }

        .table {
            border-top: 2px solid #333;
        }
        .recipient-info {
            margin-left: 0 !important;
            h4 {
                margin-bottom: 12px;
            }
            .row {
                &:not(:last-child) {
                    margin-bottom: 40px;
                }
                &:not(.address-search) {
                    flex-direction: column;
                }
                .address-search {
                    margin-bottom: 0;
                }
                .form-check-input {
                    width: 20px;
                    height: 20px;
                }
                .react-datepicker-wrapper input {
                    height: 40px;
                    padding: 10px 12px !important;
                }
                .react-datepicker__tab-loop {
                    .react-datepicker {
                        position: relative;
                        width: 100%;
                        height: 486px;
                        background: #fff;
                        z-index: 99999;
                        border-radius: 12px 12px 0 0;
                        animation: datePickerSlideUp .3s ease-in-out;
                    }
                    .react-datepicker__header {
                        .btn-prev,
                        .btn-next {
                            display: block;
                            &[disabled] {
                                visibility: hidden;
                            }
                        }
                    }
                    .react-datepicker-popper {
                        display: flex;
                        align-items: flex-end;
                        position: fixed !important;
                        height: 100%;
                        top: 0 !important;
                        bottom: 0;
                        transform: none !important;
                        border: 0 none;
                        /* Overlay */
                        &::after {
                            content: '';
                            position: fixed;
                            top: 0;
                            left: 0;
                            width: 100vw;
                            height: 100vh;
                            background: rgba(0, 0, 0, 0.5);
                            z-index: 99998;
                            animation: fadeIn .3s ease-in-out;
                        }
                    }
                }
            }
            .form-control {
                height: 40px;
                padding: 10px 12px !important;
            }
            textarea.form-control {
                height: 64px;
            }
        }
        .btn-group .dropdown-toggle {
            padding: 0 12px !important;
        }

        .row .col {
            --cui-gutter-y: 12px;
        }
        .table {
            thead {
                display: none !important;
            }
            th,
            td {
                &.check {
                    display: table-cell !important;
                    width: 30px;
                    vertical-align: top;
                    .form-check-input {
                        margin-right: 8px;
                    }
                }
                &.no {
                    display: none;
                }
                &.thumb {
                    width: 72px !important;
                    .thumb {
                        width: 72px !important;
                        height: 72px !important;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                &.product-name {
                    padding-left: 14px;
                    width: 213px;
                }
                &.cat-name {
                    display: none;
                }
                &.color {
                    display: none;
                }
                &.stock {
                    display: none;
                }
                &.action {
                    display: none;
                }
                .stock + .btn {
                    font-size: 14px !important;
                    font-weight: 500;
                    border-radius: 6px;
                    height: 34px;
                }
            }
        }
    }
    .wishlist {
        display: block;
    }
    .paybag {
        .paybag-status {
            background: rgba(229, 230, 225, 0.50); padding: 20px 24px; border-bottom: 2px solid #E5E6E1;
            .status-wrap {border-radius: 4px; background-color: white; padding: 16px; font-size: 13px; text-align: center;}
            .flex-1 {position: relative; padding: 8px 0;}
            .bb-1:after {content:""; display:inline-block; position:absolute; left:0; bottom:0; width: 85%; height: .5px; background-color: #000;}
            .bb-1.bl-1:after {right: 0; left: auto;}
            .bl-1:before {content:""; display:inline-block; position:absolute; left:0; top: 10px; width: 0.5px; height: 70%; background-color: #000;}
        }
        .list-wrap { border-top: 1px solid #000;
            li {
                border-bottom: 0.5px solid #000; padding: 14px 0;
            }
        }
        .data-wrap .data-label {
            width: 90px;
            font-size: 15px;
        }
    }
    .recipient-info {
        h4 {
            margin-bottom: 20px;
        }
        .row {
            --cui-gutter-y: 60;
        }
    }
    .member-info {
        .paybag-wrap {padding: 2rem 0; margin: auto;}
        .btn2 {height: 62px !important;}
        .btn-info {background-color: #00C73C !important;}
        .sm .btn {font-size: 14px !important; border: none; line-height: 16px;}
    }
    .member-lv {
        .request-wrap {
            display: block;
            .request {display: block; padding: 15px; text-align: center;}
            .request.on {margin-bottom: .5rem;}
        }
        .my-grade {
            background-color: white; border-radius: 4px; padding: 20px;
            .data-wrap .data-label {font-size: 11px; width: 60px;}
            .data-wrap .data {font-size: 15px;}
        }
        .my-grade.grade {
            background-color: transparent; border: 1px solid #E5E6E1;
        }
    }
    .faq {
        .page-wrap {display: flex; align-items: center; justify-content: space-between; padding: 18px 0; }
    }

    .table-wrap .btn-area {
        .form-check-input {
            background: url('../assets/img/icn_checkbox2.svg') no-repeat center;
            &:checked {
                background-image: url('../assets/img/icn_checkbox2_checked.svg');
            }
        }
        .form-check-label {
            font-weight: 700;
            font-size: 16px;
        }
        padding-left: 6px;
        align-items: center;
        justify-content: space-between !important;

        .form-check {
            display: flex;
            align-items: center;
            margin: 0;
            padding-left: 0;

            .form-check-input {
                margin: 0 10px 0 0;
            }
        }

        .btn-delete-all {
            padding: 0;
            width: 72px !important;
            height: 34px !important;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .modal-meterial-finder {
        &.is-uploading {
            .modal-content {
                height: 381px;
            }
        }
        .modal-content {
            animation: datePickerSlideUp .3s ease-in-out;
            max-width: 100vw;
            height: 274px;
            left: 0;
            top: auto;
            bottom: 0;
            transform: none;
            header {
                height: 47px;
                padding: 9px 16px 9px 20px;
                align-items: center;
                h2 {
                    font-size: 16px;
                    >small {
                        font-size: 12px;
                        line-height: 14.32px;
                        margin-left: 9px;
                    }
                }
                .close-btn {
                    background-size: 12px;
                }
            }
            .uploading-text {
                padding: 0;
                width: 100%;
                font-size: 14px;
                text-align: center;
            }
            .modal-body {
                padding: 24px;
                border-radius: 0;
                .drop-zone {
                    max-width: 100%;
                    height: 143px;
                    padding: 0 24px 0 84px;
                    font-size: 14px;
                    background-position-x: 24px;
                    background-size: 50px 50px;
                    &.error{
                        height: 216px;
                        background-position-y: calc(87.5px + 37px);
                    }
                    em {
                        margin-top: 10px;
                        font-size: 12px;
                        line-height: 18px;
                        max-width: 237px;
                        letter-spacing:-0.03em;
                    }
                    button {
                        font-size: 14px;
                    }
                    &.is-uploading {
                        padding: 40px 24px;
                    }
                    .uploader-error {
                        height: 77px
                    }
                }
                .uploader-status {
                    margin-top: 24px;
                    padding: 14px 16px 14px 62px;
                    background-size: 30px;
                    background-position: 16px center;
                    .filename {
                        font-size: 12px;
                    }
                    .uploader-progress {
                        font-size: 14px;
                        .percent {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    .table-wrap {
        .table {
            tbody {
                width: 100% !important;
                padding-right: 4px !important;
                tr {
                    width: 100% !important;
                }
                &::-webkit-scrollbar {
                    width: 4px !important;
                    height: 260px;
                    background: transparent;
                    border-radius: 50px;
                }
            }
        }
    }
    .address-search {
        margin-top: -30px;
    }

    .Toastify__toast-container {
        --toastify-toast-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        min-width: auto;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        pointer-events: none;
        .Toastify__toast {
            border-radius: 6px;
        }
        .Toastify__toast-body {
            padding: 14px 20px;
            min-width: fit-content;
        }
    }
}


.dalkom-alert {
    position: fixed;
    z-index: 10000;
    animation: fadeIn .3s;

    /* Overlay */
    &::after {
        content: "";
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 1030;
    }

    /* Dialog */
    .alert-body {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        width: 316px;
        height: 229px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: #fff;
        z-index: 1031;
    }

    .alert-icon+.alert-body {
        padding: 20px;
    }


    .alert-icon {
        display: block;
        margin: 0 auto;
    }

    .alert-content {
        margin: 40px 0 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 23.87px;
        text-align: center;
    }

    .btn-area {
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        >.btn {
            width: 100%;
            height: 45px;
            flex-shrink: 1;
            --cui-border-radius: 10px;
        }
    }

    .alert-icon+.alert-body+.btn-area {
        margin-bottom: 0;
    }
}

.dalkom-confirm {
    position: fixed;
    z-index: 10000;
    animation: fadeIn .3s;

    /* Overlay */
    &::after {
        content: "";
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 1030;
    }

    /* Dialog */
    .alert-body {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        width: 316px;
        height: 268px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: #fff;
        z-index: 1031;
    }


    .alert-icon {
        display: block;
        margin: 20px auto 0;
    }

    .alert-content {
        margin: 40px 0 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 23.87px;
        text-align: center;
    }

    .alert-icon+.alert-content {
        margin: 20px 0 0;
    }

    .btn-area {
        margin: 40px 0 0;
        width: 100%;
        display: flex;
        column-gap: 10px;
        .btn {
            width: 100%;
            flex-shrink: 1;
            --cui-btn-border-radius: 10px;
            &.btn-cancel {
                background-color: rgb(255, 255, 255) !important;
            }
        }
    }
}